import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, } from "react-redux";

import ReactDragListView from "react-drag-listview";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import "antd/dist/reset.css";
import { setLoadingStatus } from "../../redux/actions/loading";
import TransactionService from "../../services/TransactionService";
import TemplatesService from "../../services/TemplatesService";

const LibraryEditTable = ({ companyId, type, setDataForUpdateFormListOrder }) => {
    const dispatch = useDispatch();

    const [tblData, setTblData] = useState([]);



    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        dispatch(setLoadingStatus(true));
        TemplatesService.getCompanyTemplate(companyId, type, "", "")
            .then((res) => {
                setTblData(res.data.templates)
                dispatch(setLoadingStatus(false))
            }
            )
            .catch((err) => dispatch(setLoadingStatus(false)))
    }



    const userTypes = {
        "single": "Single Use",
        "multi_select": "Multi Select",
        "multiple": "Multi Use"
    }

    const columns = [
        {
            title: "",
            width: 80,
            key: "operate",
            render: () => <img src="/icons/move.svg" className="move-svg" />,
        },
        {
            title: "Order",
            width: 80,
            render: (row) => (
                <div>
                    {
                        row?.sort_order + 1 || " "
                    }
                </div>
            ),
        },
        {
            title: "Template Name",
            key: "template_name",
            grow: 2,
            sortable: true,
            render: (row) => {
                return <div data-tag="allowRowEvents">
                    <div>
                        {
                            row?.template?.template_name ? row?.template?.template_name?.replace(',', '/') : "N/A"
                        }
                    </div>
                </div>
            }
            ,
        },
        {
            title: "Association",
            render: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row?.template?.association || "N/A"
                        }
                    </div>
                </div>
            ),
        },
        {
            title: "Use Type",
            render: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            userTypes[row?.template?.use_type] || ""
                        }
                    </div>
                </div>
            ),
        },
        {
            title: "Hand Signed Only",
            center: true,
            render: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row && row?.template?.is_hand_signed ? "Yes" : "No"
                        }
                    </div>
                </div>
            ),
        },
        {
            title: "View Blank PDF",
            center: true,
            grow: 2,
            render: (row) => (
                <div>
                    <div onClick={(() => {
                        dispatch(setLoadingStatus(true));
                        TransactionService.getPdfLink(row.template_id)
                            .then((res) => {
                                dispatch(setLoadingStatus(false));
                                if (res?.data?.url) {
                                    window.open(`${res.data?.url}`, '_blank');
                                } else {
                                    toast.error("Error occurs, please try again.", {
                                        autoClose: 2000
                                    })
                                }
                            }).catch((err) => {
                                dispatch(setLoadingStatus(false));
                            })
                    })} style={{ cursor: "pointer" }}>
                        <Tooltip title="Preview Document" placement="right"> <img src="/icons/view.svg" className="svg" alt='preview' /> </Tooltip>
                    </div>
                </div>
            ),
        },
    ];

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const tbl = [...tblData];
            const item = tbl.splice(fromIndex, 1)[0];
            tbl.splice(toIndex, 0, item);
            tbl.forEach((t, index) => {
                t.sort_order = index;
            });
            const data = tbl.filter((t) => (t.id ? true : false));
            setTblData(data);
            // store updated data of sortOrder in state for every drag change 
            setDataForUpdateFormListOrder(data);
        },
        handleSelector: "img",
    };

    return (
        <>
            <div className="drag_list_view esign_drag_view">
                <ReactDragListView {...dragProps}>
                    <Table columns={columns} pagination={false} dataSource={tblData} bordered />
                </ReactDragListView>
            </div>
        </>
    );
};

export default LibraryEditTable;
