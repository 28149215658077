import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormRow from './FormRow';
import CreatableSelect from 'react-select/creatable';
import FormInputWrapper from './FormInputWapper';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import DocumentAndTaskService from '../../services/DocumentAndTaskService';
import Auth from '../../utils/auth';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { toast } from 'react-toastify';

const ManageDealsLaTagsModal = ({ show, onClose, tagsList = [], setTagsList, company_id }) => {
    const [removedTags, setRemovedTags] = useState([]); // State to store removed tags
    const logged_user = Auth.getInstance().getUserData();
    const isGlobalAdminRole = logged_user?.UserInRole?.UserRole?.name == 'Boatdox Admin';
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm({
        mode: "all",
        shouldFocusError: true,
    });
    const styles = {
        scrollContainer: {
            maxHeight: '100px', // Limit the height
            overflowY: 'auto', // Enable vertical scrolling
            border: '1px solid #ccc', // Optional: To show the boundary of the scrollable area
            borderRadius: '8px', // Optional: Rounded corners
            padding: '10px',
        },
        tagContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
        },
        tag: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#e0e0e0',
            borderRadius: '20px',
            padding: '5px 10px',
            fontSize: '14px',
        },
        closeButton: {
            marginLeft: '8px',
            background: 'none',
            border: 'none',
            fontSize: '14px',
            cursor: 'pointer',
            color: '#333',
        },
    };


    const handleRegistration = async () => {
        dispatch(setLoadingStatus(true));
        const response = isGlobalAdminRole
            ? await DocumentAndTaskService.deleteIYBATag({ tags: removedTags })
            : await DocumentAndTaskService.deleteTaskTag({ tags: removedTags, companyId: company_id });
        if (response.status === 200) {
            toast.success(response?.data);
        }
        onClose();
        dispatch(setLoadingStatus(false));
    }
    const handleError = (errors) => {
        console.log(errors);
    };
    const removeTag = (id) => {
        setTagsList(() => {
            return tagsList?.filter((e) => e?.id !== id);
        })
        setRemovedTags((ids) => {
            return [...ids, id]
        })
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Body>
                <form style={{ padding: '10px', height: '150px' }} onSubmit={handleSubmit(handleRegistration, handleError)}>
                    <FormRow>
                        <FormInputWrapper width="sm-full-width" size="three" label="Manage Tags">
                            <div style={styles.scrollContainer}>
                                <div style={styles.tagContainer}>
                                    {tagsList.map((tag, index) => (
                                        <div key={index} style={styles.tag}>
                                            <span>{tag?.tag}</span>
                                            <button
                                                type='button'
                                                style={styles.closeButton}
                                                onClick={() => removeTag(tag?.id)}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </FormInputWrapper>
                    </FormRow>
                </form>
                <div>
                    <button
                        className="uk-button uk-button-primary small"
                        type="button" onClick={() => handleRegistration()}>Save</button>
                    <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => { onClose(false) }}>
                        Cancel
                    </button>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ManageDealsLaTagsModal;