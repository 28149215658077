import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import TransactionService from '../../../../../services/TransactionService';
import { getFigure, getPrice } from '../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { buyerSellerTransaction, getTransactionEsignById, getTransactionInitialById, upsertTransaction } from '../../../../../redux/actions/transaction';
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";

import clsx from "clsx";
import moment from "moment";
import * as yup from "yup";
import { isNaN } from "lodash";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { offerFormDateFields } from '../../../../../utils/AppConstants';
import { dateFormatter } from '../../../../../utils/common';

const PanelFormOffer = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { errors }, control } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const [dateError, setDateError] = useState(null);
    const [isSelectedUSA, setSelectedUSA] = useState(true);
    const [dataTradeInVessels, setDataTradeInVessels] = useState({});
    const [requiredFields, setRequiredFields] = useState(['offer_date', 'offer_expiration_date', 'accept_reject_date', 'closing_date', 'total_purchase_price', 'less_deposit']);

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

    const dataDeal = dataTransaction || {};
    const toys = dataDeal.t_toys || [];
    const tailers = dataDeal.t_trailer || [];
    const dataOffer = dataDeal.t_offers;

    const keys = [
        "purchase_price",
        "purchase_price_additional_units",
        "total_purchase_price",
        "less_deposit",
        "balance_due",
        "deposit_due_in",
        "delivery_loc_name",
        "delivery_loc_city",
        "delivery_loc_state",
        "delivery_loc_country",
        "delivery_loc_zip",
        "delivery_loc_address",
        "offer_date",
        "offer_expiration_date",
        "accept_reject_date",
        "closing_date",
    ];

    useEffect(() => {
        if (dataDeal && dataDeal.id) {
            TransactionService.getTransactionEsignById(dataDeal.id).then((res) => {
                setDataTradeInVessels(res.data);
            }).catch((err) => {
                setDataTradeInVessels({});
            })
        }
    }, []);

    useEffect(() => {
        if (dataOffer) {
            keys.forEach((key, index) => {
                if (key.indexOf("date") > -1) {
                    setValue(
                        key,
                        dataOffer[key] ? dateFormatter(dataOffer[key]) : null
                    );
                } else if (key === "less_deposit") {
                    setValue(key, getPrice(dataOffer[key]) || 0);
                } else {
                    if (index <= 4) {
                        setValue(key, dataOffer[key] ? getPrice(dataOffer[key]) : null);
                    } else {
                        setValue(key, dataOffer[key] || null);
                    }
                }
            });

            setValue("total_purchase_price",
                getPrice((dataOffer["purchase_price"] || 0) + (dataOffer["purchase_price_additional_units"] || 0))
            );
            requiredFields.map((value) => {
                manageRequireFields(value, getValues(value));
            });
        } else {
            keys.forEach((key) => setValue(key, null));
            setValue("delivery_loc_country", "");
            setSelectedUSA(true);
        }

        const additional_price = toys.reduce((total, toy) => total + toy.item_value, 0);
        const totalAdditionalPrice = tailers.reduce((total, trailer) => total + parseFloat(trailer.price), additional_price)
        setValue('purchase_price_additional_units', getPrice(totalAdditionalPrice));
    }, [dataOffer, dataTradeInVessels]);

    useEffect(() => {
        if (dataTradeInVessels && dataTradeInVessels.t_trade_in_vessel && dataTradeInVessels.t_trade_in_vessel.allowance) {
            const allowance = dataTradeInVessels.t_trade_in_vessel.allowance;
            setValue("less_trade_allowance", getPrice(allowance));
        }
    }, [dataTradeInVessels]);

    useEffect(() => {
        if (isNaN(getFigure(getValues("deposit_due_in")))) {
            setValue("deposit_due_in", "")
        }
    }, [watch("deposit_due_in")]);

    useEffect(() => {
        setDeliveryLocCountry();
    }, [watch("delivery_loc_country")]);

    useEffect(() => {
        const total = getFigure(getValues('total_purchase_price'));
        const addt = getFigure(getValues("purchase_price_additional_units"));

        if (total >= 0) {
            setValue("purchase_price", getPrice((total || 0) - (addt || 0)));
        }

        if (!isNaN(total)) {
            setValue("balance_due",
                getPrice(parseFloat(
                    total -
                    (getFigure(getValues("less_deposit")) || 0) -
                    (getFigure(getValues("less_trade_allowance")) || 0)
                ).toFixed(2))
            );
        }
    }, [[watch("total_purchase_price"), watch("less_trade_allowance")]]);

    useEffect(() => {
        const val = getFigure(getValues("less_deposit"));

        if (!isNaN(val)) {
            setValue("balance_due",
                getPrice(parseFloat(
                    (getFigure(getValues("total_purchase_price")) || 0) -
                    val -
                    (getFigure(getValues("less_trade_allowance")) || 0)
                ).toFixed(2))
            );
        }
    }, [watch("less_deposit")]);

    useEffect(() => {
        const val = getFigure(getValues("less_trade_allowance"));
        if (!isNaN(val)) {
            setValue("balance_due",
                getPrice(parseFloat(
                    (getFigure(getValues("purchase_price")) || 0) +
                    (getFigure(getValues("purchase_price_additional_units")) || 0) -
                    (getFigure(getValues("less_deposit")) || 0) -
                    val
                ).toFixed(2))
            );
        }
    }, [watch("less_trade_allowance")]);

    const setDeliveryLocCountry = useCallback(() => {
        if (getValues("delivery_loc_country") === "USA") {
            setSelectedUSA(true);
        } else {
            setSelectedUSA(false);
        }
    });

    const changeLessDepositeAmount = () => {
        if (!isNaN(getValues("total_purchase_price"))) {
            setValue("less_deposit", getPrice(getFigure(getValues("total_purchase_price")) / 10) || 0);
        }
    }

    const checkOfferDate = () => {
        const offerDate = moment(getValues("offer_date"));
        const offerExpirationDate = getValues("offer_expiration_date");
        const acceptRejectDate = getValues("accept_reject_date");
        const closingDate = getValues("closing_date");

        if (!dateError && offerDate.isAfter(offerExpirationDate) ||
            offerDate.isAfter(acceptRejectDate) ||
            offerDate.isAfter(closingDate)) {
            setDateError("Please select a valid offer date or closing date");
            return;
        }

        const offerDate2 = getValues("offer_date");
        const offerExpirationDate2 = getValues("offer_expiration_date");
        const acceptRejectDate2 = getValues("accept_reject_date");
        const closingDate2 = moment(getValues("closing_date"));

        if (!dateError && closingDate2.isBefore(offerExpirationDate2) ||
            closingDate2.isBefore(acceptRejectDate2) ||
            closingDate2.isBefore(offerDate2)) {
            setDateError("Please select a valid offer date or closing date")
            return;
        }

        setDateError(null);
    }

    const handleBlur = (ref) => (e) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
        if (ref == "total_purchase_price") {
            changeLessDepositeAmount();
        }
    };

    const handleFocus = (ref) => (e) => {
        const price = getFigure(getValues(ref));
        setValue(ref, price);
    };


    const formAutoSaveData = (formType, amount) => {
        const existingDeposits = dataDeal.t_details.filter((detail) => { return detail.type == 'deposits' && detail.document_type == formType })[0]
        dispatch(setLoadingStatus(true));
        dispatch(buyerSellerTransaction({
            step: {
                db: "details",
                main: [{
                    amount: amount || 0,
                    date: dataTransaction?.t_details?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))?.[0]?.date??null,
                    type: "deposits",
                    description: dataTransaction?.t_details?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))?.[0]?.description ?? "Initial Deposit",
                    document_type: formType,
                    id: dataTransaction?.t_details?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))?.[0]?.id
                }],
                detailType: "deposits",
                documentType: formType
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "")
        ).then((data) => {
            dispatch(getTransactionEsignById(dataTransaction.id)).then((response) => {
                dispatch(setLoadingStatus(false));
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
            })
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
        })

    }

    const handleRegistration = (offer) => {
        dispatch(setLoadingStatus(true));
        offer.purchase_price = getFigure(offer.purchase_price);
        offer.purchase_price_additional_units = getFigure(offer.purchase_price_additional_units);
        offer.total_purchase_price = getFigure(offer.total_purchase_price);
        offer.less_deposit = getFigure(offer.less_deposit || 0);
        offer.less_trade_allowance = getFigure(offer.less_trade_allowance);
        if (dataOffer && dataOffer.id) {
            offer.id = dataOffer.id;
        }

        // change all date string in yyyy-MM-DD format
        for (const index in offerFormDateFields) {
            offer[offerFormDateFields[index]] = dateFormatter(offer[offerFormDateFields[index]]);
        }

        offer = getFormattedData(offer);
        dispatch(upsertTransaction({
            step: {
                db: "offer",
                main: offer,
            },
            transaction: {
                isBelonged: false,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                },
            },
        }, "Offer")).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionInitialById(data.id));
            closePanel(true);
        }).catch((error) => {
            console.log(error);
            dispatch(setLoadingStatus(false));
        });

        // formAutoSaveData("seller", offer?.less_deposit || 0)
        formAutoSaveData("buyer", offer?.less_deposit || 0);
    };

    const handleError = (error) => {
        console.log(error);
    };

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        });
    }

    return (
        <div className="form-container panel-form">
            {dateError &&
                <div className="uk-alert uk-alert-danger" data-uk-alert>
                    <p>{dateError}</p>
                </div>
            }
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Offer Date *">
                        <Controller
                            control={control}
                            name="offer_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('offer_date') })}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        setValue("offer_date", dateString);
                                        manageRequireFields('offer_date', dateString);
                                        checkOfferDate();
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Offer Expiration Date *">
                        <Controller
                            control={control}
                            name="offer_expiration_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('offer_expiration_date') })}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        setValue("offer_expiration_date", dateString);
                                        manageRequireFields('offer_expiration_date', dateString);
                                        checkOfferDate();
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Accept/Reject Date *">
                        <Controller
                            control={control}
                            name="accept_reject_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('accept_reject_date') })}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        setValue("accept_reject_date", dateString);
                                        manageRequireFields('accept_reject_date', dateString);
                                        checkOfferDate();
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Closing Date *">
                        <Controller
                            control={control}
                            name="closing_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('closing_date') })}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        setValue("closing_date", dateString);
                                        manageRequireFields('closing_date', dateString);
                                        checkOfferDate();
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Deposit Due In (# of Days)">
                        <input className="uk-input" type="text"
                            {...register("deposit_due_in")} defaultValue={3} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Purchase Price (Vessel)">
                        <input className="uk-input" type="text"
                            {...register("purchase_price")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Purchase Price (Addt'l Units)">
                        <input className="uk-input" type="text"
                            {...register("purchase_price_additional_units")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Total Purchase Price *">
                        <input
                            className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('total_purchase_price') })}
                            type="text"
                            {...register("total_purchase_price", {
                                onChange: e => {
                                    manageRequireFields('total_purchase_price', e.target.value);
                                }
                            })}
                            onBlur={handleBlur("total_purchase_price")}
                            onFocus={handleFocus("total_purchase_price")}
                        />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Less Deposit * (-)">
                        <input className="uk-input" type="text" {...register("less_deposit")}
                            onBlur={handleBlur("less_deposit")} onFocus={handleFocus("less_deposit")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Less Trade Allowance (-)">
                        <input className="uk-input" type="text"
                            {...register("less_trade_allowance")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Balance">
                        <input className="uk-input" type="text" {...register("balance_due")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <h5 className="input-view">Delivery Location
                    </h5>
                    <p>
                        Description of delivery location is required. Examples are: At present berth, Seller's home,  Brokerage Dock, or a specific marina etc.
                    </p>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Description">
                        <input className="uk-input" type="text"
                            {...register("delivery_loc_name")} defaultValue="At present berth" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Address">
                        <input className="uk-input" type="text" {...register("delivery_loc_address")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Country">
                        <select className="uk-select" {...register("delivery_loc_country")} defaultValue="">
                            {Country.map((country) => {
                                return (
                                    <option value={country.code} key={country.code}>
                                        {country.name}
                                    </option>
                                );
                            })}
                        </select>
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="City">
                        <input className="uk-input" type="text" {...register("delivery_loc_city")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="State">
                        {isSelectedUSA
                            ? (
                                <select className="uk-select" {...register("delivery_loc_state")}>
                                    {StatesForUSA.map((state) => {
                                        return (
                                            <option value={state.code} key={state.code}>
                                                {state.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            ) : (
                                <input className="uk-input" type="text" {...register("delivery_loc_state")} />
                            )
                        }
                    </FormInputWrapper>
                    <FormInputWrapper label="Zip/Postal Code">
                        <input className="uk-input" type="text" {...register("delivery_loc_zip")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormOffer;