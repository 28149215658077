import { isNumber } from "lodash";

export function getPrice(cent, symbol = "$") {
  if (cent && cent.toString().indexOf("$") > -1) return cent;
  if (!cent && cent !== 0) return null;
  let price = parseFloat(`${cent}`).toFixed(2);
  if (price >= 0) {
    price = price.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return `${symbol}${price}`;
  } else {
    return `-${symbol}${parseFloat(Math.abs(price))
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  }
}

export function getFigure(cent, symbol = "$") {
  if (!cent && cent !== 0) {
    return null;
  }
  if (isNumber(cent)) {
    return cent;
  } else {
    return parseFloat(cent.replace(`${symbol}`, "").replaceAll(",", ""));
  }
}

// var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

// export function abbreviateNumber(number) {

//   const n = parseFloat(number);

//   if (n < 1e3) return n;
//   if (n >= 1e3 && n < 1e6) return "$" + (n / 1e3).toFixed(1) + "K";
//   if (n >= 1e6 && n < 1e9) return "$" + (n / 1e6).toFixed(1) + "M";
//   if (n >= 1e9) return "$" + (n / 1e9).toFixed(1) + "B";

//   // format number and add suffix
//   // return "$" + scaled.toFixed(1) + suffix;
// }

export function humanizeDate(date_str) {
  let date = date_str.split("T")[0];
  const month = [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date_arr = date.split("-");

  return ` ${month[Number(date_arr[1]) - 1]} ${date_arr[2]} , ${date_arr[0]}`;
}
