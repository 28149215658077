import React, { useState } from 'react';
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import FormRow from './FormRow';
import Column from './Column';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { createFileFromLink, getEsignedDocumentpath } from '../../utils/common';
import { fileUploadWithServer, uploadForms } from '../../utils/s3';
import { resetEdoc } from '../../redux/actions/transaction';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    "borderRadius": "20px",
    bgcolor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    p: 0,
};

export const DocumentResetModal = ({ edoc, logged_user, onClose, uniqueId, companyUID }) => {

    const dispatch = useDispatch();

    const [withManualReset, setWithManualReset] = useState(false);

    const uploadDocLink = async (doc) => {
        if (doc) {
            try {
                const file = await createFileFromLink(doc, doc.split('/')[doc.split('/').length - 1]);
                const filename = await uploadForms(file, 'reset-documents', uniqueId, companyUID);
                return filename;
            } catch (error) {
                console.error("ERROR:", error)
                return null;
            }
        }
        return null;
    }

    const onSubmitReset = () => {
        dispatch(setLoadingStatus(true));
        uploadDocLink(edoc.request_signature?.is_manual_updated && edoc.request_signature?.signers?.filter(signer => signer.status == 'Signed').length == 0
            ? `https://${process.env.REACT_APP_PDF_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}pdf-documents/${edoc.request_signature.doc_link}`
            : getEsignedDocumentpath(edoc.request_signature?.doc_link)).then(res => {
                const uploadedDoc = res;
                dispatch(resetEdoc(edoc.id, {
                    action: edoc.title,
                    activity_type: 'reset_document',
                    actionBy: logged_user.id,
                    transaction_id: edoc.transaction_id,
                    document_title: edoc.title,
                    document_link: uploadedDoc,
                    isManual: withManualReset
                })).then((res) => {
                    dispatch(setLoadingStatus(false));
                    toast.success('Document reset successfully.')
                    onClose(true);
                }).catch(err => {
                    dispatch(setLoadingStatus(false));
                });
            }).catch(err => {
                console.log(err);
                if (err.message.includes('401')) {
                    dispatch(setLoadingStatus(false));
                    toast.error('You are not authorized to access this action.');
                    return;
                } else {
                    toast.error('could not reset document!')
                }
            });
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            style={{
                borderRadius: "20px"
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}>
            <Box sx={style}>
                <div className="uk-modal-header" style={{ borderRadius: "inherit" }}>
                    <h4 className="uk-modal-title" >Reset Document</h4>
                </div>
                <div className="uk-modal-body">
                    <div className="radio-toolbar">
                        <FormRow className="d-flex">
                            <input
                                className='mb-0'
                                type="radio"
                                value="false"
                                id='remove_sign_only'
                                name='remove_sign_only'
                                checked={!withManualReset}
                                onChange={() => setWithManualReset(false)}
                            />
                            <label htmlFor='remove_sign_only' className="uk-form-label radio" style={{ fontSize: '15px' }}>Remove signature(s) or signature request(s) only</label>
                        </FormRow>
                        {/* <FormRow className="d-flex align-items-center ">
                            <input
                                type="radio"
                                value="true"
                                id='remove_all'
                                name='remove_all'
                                checked={withManualReset}
                                onChange={() => setWithManualReset(true)}
                            />
                            <label htmlFor='remove_all' className="uk-form-label mb-0" style={{ fontSize: '15px' }}>Remove all edits & update Form Data</label>
                        </FormRow> */}
                    </div>
                </div>
                <div className="uk-modal-footer uk-text-right" style={{ borderRadius: "inherit" }}>
                    <button value="Submit" className="uk-button uk-button-orange" type="button"
                        onClick={() => onClose(false)}>Close</button>
                    <button value="Submit" className="uk-button uk-button-primary" type="button"
                        onClick={onSubmitReset}>Submit</button>
                </div>
            </Box>
        </Modal>
    )
}
