import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormRow from './FormRow';
import CreatableSelect from 'react-select/creatable';
import FormInputWrapper from './FormInputWapper';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const AddEsignTagsModal = ({ show, onClose, doc = {}, updateTagsField, dataTags }) => {
    const createOption = (label, value) => ({ label, value });
    const [tagList, setTagList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState('');
    const schema = yup.object().shape({
        tags: yup.mixed().required()
    });

    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    useEffect(() => {
        reset();
        if (doc?.tags) {
            setTags([...(doc?.tags?.map((e) => {
                return createOption(e, e)
            }) || [])]);
            setValue('tags', [...(doc?.tags?.map((e) => {
                return createOption(e, e)
            }) || [])]);
            setTagList(doc?.tags?.map((e) => {
                return createOption(e, e)
            }) ?? []);
        }
        return () => {
            setTags([]);
            setValue('tags', []);
            setTagList([]);
        }
    }, [doc]);

    const handleRegistration = () => {
        updateTagsField(doc?.id, tags?.map((e) => { return e?.value }))
    }
    const handleError = (errors) => {
        console.log(errors);
    };
    const handleKeyDown = async (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const isTagExist = !!(tagList.find(tag => tag.label == inputValue?.toLowerCase()));
                if (!isTagExist) {
                    setTags((tags) => [...tags, createOption(inputValue, inputValue)]);
                    setValue('tags', [...(getValues('tags') || []), inputValue]);
                    setTagList([...tagList, createOption(inputValue, inputValue)]);
                    setInputValue('');
                }
                event.preventDefault();
        }
    };
    const handleTagSelect = (tagList) => {
        if (tagList.length == 0) {
            setTags([]);
            return;
        }
        tagList.map(tag => {
            if (tag.__isNew__ || inputValue) return;
            setTags(tagList);
        });
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Body>
                <form style={{ padding: '10px', height: '180px' }} onSubmit={handleSubmit(handleRegistration, handleError)}>
                    <FormRow>
                        <FormInputWrapper width="sm-full-width" size="three" label="Tags">
                            <CreatableSelect
                                options={dataTags?.map((e) => { return { label: e, value: e } })}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                onChange={(newValue) => { handleTagSelect(newValue) }}
                                onInputChange={(newValue) => setInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter tag name"
                                value={tags}
                                formatCreateLabel={(e) => `Create '${e}' by pressing enter`}
                                // menuIsOpen={true}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: "100px",  // Adjust the value as needed
                                        overflow: 'hidden',  // Ensure the list is scrollable if content overflows
                                    }),
                                }}
                            />
                        </FormInputWrapper>
                    </FormRow>
                </form>
                <div>
                    <button
                        className="uk-button uk-button-primary small"
                        type="button" onClick={() => handleRegistration()}>Save</button>
                    <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => { onClose(false) }}>
                        Cancel
                    </button>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddEsignTagsModal;