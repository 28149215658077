import { useEffect, useRef, useState } from 'react';
import VesselAndToyService from '../../services/VesselAndToyService';
import "./searchMLSVessel.css";

const SearchMLSVessel = ({ userId, onSelect, from, searchProps = {}, condition = false }) => {

    // state for search query param like it is search by id/mmake/hin/model or name
    const { searchByHIN, setSearchBYHIN } = searchProps;

    const [vesselList, setVesselList] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [isSelectVessel, setIsSelectVessel] = useState(false);
    const [From, setFrom] = useState(from);
    const [isFetching, setIsFetching] = useState(null); // To avoid multiple API calls
    const [search, setSearch] = useState("");
    const mlsListRef = useRef(null);

    const handleSearchVessel = async (searchText, page = 1, limit = 10) => {
        setSearchValue(searchText);
        setIsSelectVessel(false);

        if (page === 1) {
            setVesselList({
                data: []
            }); // Clear the list for a fresh search
        }

        if (searchText && searchText.length > 2) {
            setIsLoading(true);
            try {
                const { data } = await (from !== "deal" ? VesselAndToyService.retrieveMlsVessel(userId, searchText, searchByHIN, page, limit) : VesselAndToyService.retrieveMlsVesselWithoutBroker(userId, searchText, searchByHIN, page, limit));
                setIsLoading(false);
                if (data?.data?.length == 0 && page <= 1) {
                    setIsEmpty(true);
                    return;
                }
                setIsEmpty(false);
                setVesselList({ ...data, data: page === 1 ? data?.data : [...vesselList?.data, ...data?.data] });
            } catch (error) {
                setIsLoading(false);
                setIsEmpty(true);
                console.log(error);
            }
        }

    }

    const handleVesselClick = (vessel) => {
        setTimeout(() => {
            setIsSelectVessel(true);
            setSearchValue("");
        }, 1500);
        onSelect(vessel)
    }

    const handleBlur = () => {
        setTimeout(() => {
            setIsSelectVessel(true);
            setSearchValue("");
        }, 800);
    }

    const displayImage = (vessel) => {
        return <img itemprop="image" src={vessel?.displayImageLink} class="img-fluid" alt="vessel-img" />;
    }

    const handleScroll = () => {

        const container = mlsListRef.current;
        if (!container) return;

        // Check if scrolled to the bottom
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {

            if (!isFetching && vesselList?.data?.length && vesselList?.page < vesselList?.totalPages) {

                setIsFetching(true);
                handleSearchVessel(search, vesselList?.page + 1, 10);
            }
        }
    };

    useEffect(() => {
        const container = mlsListRef.current;
        if (!container) return;

        // Attach scroll event listener
        container.addEventListener('scroll', handleScroll);

        // Cleanup event listener on unmount
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [isFetching]);

    useEffect(() => {
        if (vesselList?.data?.length > 0) {
            setIsFetching(false); // Reset fetching state when new data arrives
        }
    }, [vesselList?.data?.length]);

    return (
        <div className="one-column uk-padding-small custom-drop">
            <div class="col-12 m-0 p-0">
                <label className="uk-form-label mb-0" htmlFor="search">Search Yachtbroker.org MLS</label>
                <div className="radio-toolbar" style={{ marginTop: "10px" }}>
                    <input id='searchById' type="radio" value={false} checked={searchByHIN === false} onChange={(e) => setSearchBYHIN(false)} name="searchOption" />
                    <label htmlFor='searchById' className="uk-form-label radio">Search By Vessel ID/ Make/ Model or Name</label>
                    <input id='searchByHIN' type="radio" value={true} checked={searchByHIN === true} onChange={(e) => setSearchBYHIN(true)} name="searchOption" />
                    <label htmlFor='searchByHIN' className="uk-form-label radio">Search By HIN</label>
                </div>
                <div className="vessel-input input-group">
                    <input
                        aria-label="Search placeholder"
                        value={searchValue}
                        class="rounded-pill-left form-control border border-right-0 font-size"
                        //   style={{fontSize:12.5}}
                        type="text"
                        name="search"
                        placeholder="Enter Vessel ID, HIN, Make, Model or Name to search MLS"
                        autocomplete="off"
                        onChange={(e) => {
                            handleSearchVessel(e.target.value);
                            if (!e.target.value) {
                                setIsFetching(null);
                            }
                            setSearch(e.target.value)
                        }}
                        onBlur={handleBlur}
                    />
                    <button
                        class="btn searchBtn rounded-pill-right btn-outline-secondary border border-left-0 mytooltip"
                        type="button">
                        <i class="fa fa-search" aria-label="Search Icon" role="search"></i>
                    </button>
                </div>
                
                {condition && <span className='text-danger'>Can't change MLS reference vessel</span>}

                {isLoading && !isSelectVessel && (!vesselList?.data?.length || vesselList?.page) &&
                    <div class="dropdown-menu mt-2 loader-div show show-dropdown" id="yacht_list_search_div">
                        <div id="yacht_search_list">
                            <a data-href="/yacht-details?id=84416&amp;vessel=2796732&amp;title=73-ft-Custom-2005-True-Love-Deltaville-Virginia-United-States-$999500" class="dropdown-item search_vessel_detail_page_btn" href="javascript:;">
                                <div class="row m-1">
                                    Loading...
                                </div>
                            </a>
                        </div>
                    </div>
                }

                {isEmpty && !isLoading && !isSelectVessel &&
                    <div class="dropdown-menu mt-2 loader-div show show-dropdown" id="yacht_list_search_div">
                        <div id="yacht_search_list">
                            <a data-href="/yacht-details?id=84416&amp;vessel=2796732&amp;title=73-ft-Custom-2005-True-Love-Deltaville-Virginia-United-States-$999500" class="dropdown-item search_vessel_detail_page_btn" href="javascript:;">
                                <div class="row m-1">
                                    No Data Found.
                                </div>
                            </a>
                        </div>
                    </div>
                }

                {(((!isLoading && vesselList?.page) || vesselList?.data?.length > 0) && !isSelectVessel) &&
                    <div class="dropdown-menu mt-2 show " id="yacht_list_search_div" ref={mlsListRef} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <div id="yacht_search_list">
                            {
                                vesselList?.data?.map((vessel) => (
                                    <div class="dropdown-item search_vessel_detail_page_btn" onClick={() => handleVesselClick(vessel)}>
                                        <div class="row m-1">
                                            <div class="col-3 d-sm-block d-sm-none d-md-block ml-0 pl-0">
                                                {displayImage(vessel)}
                                            </div>
                                            <div class="col-9">
                                                <p><strong>{`${vessel?.displayLengthFeet} ${vessel?.manufacturer} ${vessel?.year}`}</strong><span className='pl-3'>(ID: {vessel?.id})</span>
                                                    <br />{vessel?.model} - <i>{vessel?.vesselName}</i>
                                                    <br />{vessel?.listingOwnerName} - <i>{vessel?.listingOwnerBrokerageName}</i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {isLoading && !isSelectVessel && vesselList?.data?.length > 0 &&
                            <div id="yacht_search_list">
                                <a data-href="/yacht-details?id=84416&amp;vessel=2796732&amp;title=73-ft-Custom-2005-True-Love-Deltaville-Virginia-United-States-$999500" class="dropdown-item search_vessel_detail_page_btn" href="javascript:;">
                                    <div class="row m-1">
                                        Loading...
                                    </div>
                                </a>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default SearchMLSVessel;
