import { Box } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from 'pdf-lib';
import { Buffer } from "buffer";
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const PdfPagination = ({ doc, pageProps, mergeProps, getEditedPdfUrl, undoProps, redoProps, setFieldTrackArr, onSaveDocumentVersion, onMergeDocument, onDeleteDocumentPage, templatePageCount, reloadKey }) => {
  const { pageNumber, setPageNumber, numPages, setNumPages } = pageProps;

  const { mergedPdfUrl, setMergedPdfUrl, files, setFiles, isConfirmForMerge, setIsConfirmForMerge, mergeRef } = mergeProps;

  const { disableUndo, setDisableUndo } = undoProps;
  const { disableRedo, setDisableRedo } = redoProps;

  const dispatch = useDispatch();
  // in this useEffect pdf will merge based on edition
  useEffect(() => {

    if (!isConfirmForMerge) {
      if (disableUndo) {
        files.length && mergePdfs(doc);
      }
      else {
        files.length && getEditedPdfUrl(doc).then((res) => mergePdfs(res));
      }
    } else {
      setIsConfirmForMerge(false);
    }

  }, [doc, files]);

  // in this useEffect undo redo will disable after merge
  useEffect(() => {

    if (mergedPdfUrl) {
      // disable undo redo button and do empty changes tracking array after merge pdf
      setDisableUndo(true);
      setDisableRedo(true);
      setFieldTrackArr([]);
    }
  }, [mergedPdfUrl]);

  const mergePdfs = async (initialUrl) => {
    try {
      dispatch(setLoadingStatus(true));

      // Initialize the merged PDF
      let mergedPdf;

      if (mergedPdfUrl) {
        // Load the existing merged PDF if it exists
        const existingPdfBytes = await fetch(mergedPdfUrl).then((res) => res.arrayBuffer());
        mergedPdf = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
      } else {
        // Create a new PDFDocument if no merged PDF exists
        mergedPdf = await PDFDocument.create();
      }

      // If a main document (initial file) exists and hasn't been added yet
      if (!mergedPdfUrl && initialUrl) {
        const mainPdfBytes = await fetch(initialUrl).then((res) => res.arrayBuffer());
        const mainPdf = await PDFDocument.load(mainPdfBytes, { ignoreEncryption: true });
        const mainPdfPages = await mergedPdf.copyPages(mainPdf, mainPdf.getPageIndices());
        mainPdfPages.forEach((page) => mergedPdf.addPage(page));
      }

      // Add new files into the merged PDF
      for (const file of files) {
        const fileArrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
        const additionalPdf = await PDFDocument.load(fileArrayBuffer, { ignoreEncryption: true });
        const additionalPdfPages = await mergedPdf.copyPages(additionalPdf, additionalPdf.getPageIndices());
        additionalPdfPages.forEach((page) => mergedPdf.addPage(page));
      }

      // Serialize the merged PDF to bytes
      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

      // Update the merged PDF URL state
      const newMergedPdfUrl = URL.createObjectURL(blob);

      // Pass the merged PDF URL to your callback (if needed)
      await onMergeDocument(newMergedPdfUrl, initialUrl === "");
      dispatch(setLoadingStatus(false));
    } catch (error) {
      console.error('Error during PDF merge:', error);
      dispatch(setLoadingStatus(false)); // Ensure loading state is false in case of error
    }
  };

  // function for delete page from pdf
  const deletePageFromPdf = async (pdfUrl, pageNumberToDelete) => {
    try {
      dispatch(setLoadingStatus(true));

      // Load the PDF
      const pdfDoc = await PDFDocument.load(await fetch(pdfUrl).then(res => res.arrayBuffer()), { ignoreEncryption: true });

      // Get all page indices, and remove the page by index (pageNumberToDelete is 1-based)
      const pageIndices = pdfDoc.getPageIndices();
      const pageIndexToDelete = pageNumberToDelete;

      if (pageIndices.includes(pageIndexToDelete)) {
        pdfDoc.removePage(pageIndexToDelete);
      } else {
        throw new Error('Page number does not exist.');
      }

      // Serialize the modified PDF to bytes
      const modifiedPdfBytes = await pdfDoc.save();
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

      // Trigger a render the modified PDF
      await onDeleteDocumentPage(URL.createObjectURL(blob), pageNumberToDelete + 1, numPages === templatePageCount + 1);

      dispatch(setLoadingStatus(false));
    } catch (error) {
      console.error('Error during page deletion:', error);
      dispatch(setLoadingStatus(false)); // Ensure loading state is false in case of error
    }
  };

  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const handleFileChange = async (e) => {
    // manage selected file in one state for merging purpose
    setFiles((prev) => [e.target.files[0]]);
  }

  return (
    fileOptions?.url ? <Box sx={{ border: 0 }}>
      <Document
        key={reloadKey}
        file={fileOptions}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div key={index} style={{ border: index + 1 === pageNumber ? '2px solid #3e8eff' : 'none' }} className="d-flex flex-column justify-center m-1">
            <div style={{ cursor: 'pointer' }} className="p-2">
              <Page
                scale={0.16}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                style={{ height: 70, width: 55, cursor: 'pointer' }}
                onLoadError={(error) => alert('Error while loading doc! ' + error.message)}
                onClick={() => setPageNumber(index + 1)}
              />
            </div>
            <span style={{ textAlign: "center", fontSize: "15px", background: index + 1 === pageNumber ? '#3e8eff' : 'transparent' }}>
              {index + 1}
              {(templatePageCount < index + 1) && <i
                className="fa fa-trash  text-danger ml-1"
                aria-hidden="true"
                style={{ fontSize: `15px`, cursor: "pointer" }}
                onClick={(e) => deletePageFromPdf(fileOptions?.url, index)}
                draggable={false}
              ></i>}
            </span>
          </div>
        ))}
        <div className="w-100 d-flex justify-content-center">
          <div className="shadow d-flex align-items-center justify-content-center  add-pdf-button rounded p-1">
            <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={mergeRef} hidden />
            <span className="h-100 w-100 d-flex align-items-center justify-content-center" onClick={handleSelectFile}>+</span>
          </div>
        </div>
      </Document>
    </Box> :
      <div className="w-100 d-flex justify-content-center m-1">
        <div className="shadow d-flex align-items-center justify-content-center  add-pdf-button rounded p-1">
          <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={mergeRef} hidden />
          <span className="h-100 w-100 d-flex align-items-center justify-content-center" onClick={handleSelectFile}>+</span>
        </div>
      </div>

  );
};

export default PdfPagination;
