import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CookiesProvider } from 'react-cookie';

import store from "./redux/store";

import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import ESignLayout from "./layouts/ESignLayout";
import MainRoute from "./layouts/main_route";

import Privacy from "./components/privacy";
import Terms from './components/terms';
import Loading from "./components/loading";

import './App.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Maintenance from "./components/maintenance/Maintenance";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UnderConstruction from "./components/mobile_page/UnderConstruction";
import HealthStatus from "./components/health_status/index";
const App = () => {
  const isLoading = useSelector((state) => state.loading);
  const persistor = persistStore(store);

  const history = useHistory();
  const mainPath = history.location.pathname.split('/')[1]
  // const maintenance = false;

  // if (maintenance) {
  //   history.push("/maintenance")
  // } else {
  //   history.push("/dashboard")
  // }
  function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iPad, iPod, or iPhone
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return /iPhone|iPod/.test(userAgent);
    }

    // Check for Android tablets
    if (/android/i.test(userAgent)) {
      // Check for tablet
      if (!/mobile/i.test(userAgent)) {
        return false;
      }
      // Check for phone
      return true;
    }

    // Check for other mobile devices
    const mobilePattern = /BlackBerry|webOS|Opera Mini|IEMobile|Mobile|Silk/i;
    if (mobilePattern.test(userAgent)) {
      return true;
    }

    // Default to false (assuming desktop or other devices)
    return false;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <ToastContainer position="top-right" autoClose={5000} newestOnTop={true} closeOnClick />
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={<Loading loading={true} />} persistor={persistor}>
            {isLoading.status && <Loading loading={isLoading.status} message={isLoading.msg} />}
            <Router>
              <Switch>
                {/* {
                  maintenance
                    ? <Route path="/maintenance" component={Maintenance} />
                    : <> */}
                <Route path="/e-sign" component={ESignLayout} />
                {(isMobile()) && <Route path="*" component={UnderConstruction} />}
                <Route path="/auth" component={AuthLayout} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                {/* health status route */}
                <Route path="/health-status" component={HealthStatus} />
                <MainRoute path="/" component={MainLayout} />
                {/* </>
                } */}
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </DndProvider>
  );
};

export default App;
