import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmAlert } from "react-confirm-alert";
import PhoneInput from "react-phone-input-2";

import Form from "../../../../common/Form";
import FormRow from '../../../../common/FormRow';
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import AutoCompleteSearch from '../../../../common/Autocomplete';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { clearClient, findClientByName, getClient, getTransactionClient } from '../../../../../redux/actions/clients';
import { getTransactionInitialById, upsertTransaction } from '../../../../../redux/actions/transaction';
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import {
    CORPORATE_OWNED,
    LLC_OWNED,
    TRUST_OWNED,
    LEGAL_OWNER,
    RECORD_OWNER,
    JOINT_OWNER,
    CLIENT_REQUIRED_FIELDS
} from "../../../../../utils/signer";

import clsx from "clsx";
import Auth from '../../../../../utils/auth';
import * as yup from "yup";

const useStyles = makeStyles({
    divider: {
        marginBottom: "15px",
    },
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    phoneInputDiv: {
        padding: "8px",
        marginLeft: "-7px",
        marginRight: "7px",
        marginTop: "-7px"
    }
});

const MAX_BENEFICIAL_OWNERS = 4;

const PanelFormSeller = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({

    });
    const { register, handleSubmit, setValue, getValues, reset, watch } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const classes = useStyles();
    const logged_user = Auth.getInstance().getUserData();

    const [error, setError] = useState("");
    const [isSelectedUSA, setSelectedUSA] = useState(true);
    const [isSelectedUSA2, setSelectedUSA2] = useState(true);
    const [isEditSeller, setIsEditSeller] = useState(false);
    const [isOwnerOrLegal, setIsOwnerOrLegal] = useState(1);
    const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);
    const [isHideFromOtherBroker, setIsHideFromOtherBroker] = useState(false);

    // const [numberOfJointOwners, setNumberOfJointOwners] = useState(1);
    const [numberOfBeneficialOwners, setNumberOfBeneficialOwners] = useState(1);

    // State for save MLS checkbox
    const [isGlobal, setIsGlobal] = useState(false);

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataClient = useSelector((state) => state.clients.client);
    const dataClients = useSelector((state) => state.clients.list);
    const dataDeal = dataTransaction || {};

    const optionsForAutocomplete = dataClients
        .map((client) => {
            return { id: client.id, value: `${client.first_name} ${client.last_name}` };
        })
        .sort((prev, next) => prev.value.localeCompare(next.value))
        .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

    const isOwnerInGoodStanding = useMemo(() => getValues('trust.trust_is_good_standing'), [watch('trust.trust_is_good_standing')]);
    const sellerType = useMemo(() => getValues('seller_type'), [watch('seller_type')]);

    useEffect(() => {
        if (dataDeal.seller_contact_id) {
            dispatch(getTransactionClient(dataDeal.seller_contact_id));
            setIsEditSeller(true);
        } else {
            setValue("country", "");
            setSelectedUSA(true);
            setIsEditSeller(false);
        }
    }, []);

    useEffect(() => {
        dispatch(findClientByName());

        return () => {
            dispatch(clearClient());
        };
    }, []);

    useEffect(() => {
        if (dataDeal.owner_or_legal) {
            setIsOwnerOrLegal(dataDeal.owner_or_legal);
        }

        if (dataDeal.seller_type) {
            setValue('seller_type', dataDeal.seller_type)
        } else {
            setValue('seller_type', RECORD_OWNER);
        }
    }, [dataTransaction]);

    useEffect(() => {
        if (!!dataClient) {
            // setNumberOfJointOwners(Number(dataClient["number_of_joint_owners"]) || 1);
            setNumberOfBeneficialOwners(Number(dataClient["number_of_beneficial_owners"]) || 1);

            Object.keys(dataClient).forEach((key) => {
                if (key.indexOf('trust') === 0) {
                    setValue(`trust.${key}`, dataClient[key]);
                }
                else if (key === "beneficial_owners") {
                    let beneficialOwners = JSON.parse(dataClient[key] || "{}") || {};
                    Object.keys(beneficialOwners).forEach((key, index) => {
                        //fill up only fields equalt to count stored in database
                        let beneficialOwnerNumber = Number(key.split("_").pop());
                        if (beneficialOwnerNumber <= Number(dataClient["number_of_beneficial_owners"])) {
                            setValue(`beneficial_owners.${key}`, beneficialOwners[key]);
                        }
                    });
                }
                else {
                    setValue(key, dataClient[key]);
                }
                setIsHideFromOtherBroker(dataDeal.is_seller_hide_by_invited_broker || false);
            });
            requiredFields.map((value) => {
                manageRequireFields(value, getValues(value));
            });
        } else {
            const selectedSellerType = getValues('seller_type') || "";
            reset();
            setValue('seller_type', selectedSellerType);
            setValue("country", "");
            setSelectedUSA(true);
        }
    }, [dataClient]);

    useEffect(() => {
        if (getValues("country") === "USA") {
            setSelectedUSA(true);
        } else {
            setSelectedUSA(false);
        }
    }, [watch("country")]);

    useEffect(() => {
        if (getValues("trust.trust_country") === "USA") {
            setSelectedUSA2(true);
        } else {
            setSelectedUSA2(false);
        }
    }, [watch("trust.trust_country")]);

    const handleRegistration = (seller) => {
        dispatch(setLoadingStatus(true));
        seller = getFormattedData({
            ...seller,
            ...seller.trust
        });
        seller['beneficial_owners'] = JSON.stringify(seller['beneficial_owners']);

        if (dataDeal && dataDeal.seller_contact_id) {
            seller.id = dataDeal.seller_contact_id;
        }

        if (!isEditSeller) {
            delete seller.id;
        }

        if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(sellerType) > -1) {
            seller['first_name'] = seller.trust?.trust_name;
            seller['mobile_phone'] = seller.trust?.trust_signer_phone;
            seller['email'] = seller.trust?.trust_signer_email;
        }

        dispatch(upsertTransaction({
            step: {
                db: "seller",
                isGlobal: isGlobal,
                main: {
                    ...seller,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    createdBy: logged_user?.id,
                    updatedBy: logged_user?.id
                },
            },
            transaction: {
                isBelonged: true,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    owner_or_legal: isOwnerOrLegal,
                    seller_type: sellerType,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    is_seller_hide_by_invited_broker: isHideFromOtherBroker
                },
                foreignkeyName: "seller_contact_id",
            }
        }, "Seller")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionInitialById(data.id));
            dispatch(clearClient());
            closePanel(true);
        }).catch((err) => {
            setError(err.response.data.message);
            dispatch(setLoadingStatus(false));
            setTimeout(() => {
                setError("");
            }, 5000);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    const handleSelect = (value, option) => {
        dispatch(getClient(option.id));
    };

    const handleSeller = (status) => {
        if ([CORPORATE_OWNED, LLC_OWNED, TRUST_OWNED, JOINT_OWNER].indexOf(status) > -1) {
            confirmAlert({
                title: "Set Title",
                overlayClassName: "title-overlay-custom-class",
                message:
                    "You have chosen a title other than Legal Name for the owner of this vessel. Confirm you have a Power of Attorney or other legal instrument allowing this person to sell this vessel",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            setValue('seller_type', status);
                            if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
                                setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
                                setValue("number_of_beneficial_owners", numberOfBeneficialOwners || 1);
                                ['trust_name', 'trust_signer_phone', 'trust_signer_email'].map((value) => {
                                    manageRequireFields(value, getValues(`trust.${value}`));
                                });
                            } else {
                                CLIENT_REQUIRED_FIELDS.map((value) => {
                                    manageRequireFields(value, getValues(`${value}`));
                                });
                            }

                            setValue("trust.trust_country", "");
                        }
                    },
                    {
                        label: "No",
                        onClick: () => { },
                    },
                ],
            });
        } else {
            setValue('seller_type', status);
            if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
                setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
                setValue("number_of_beneficial_owners", numberOfBeneficialOwners || 1);
            }
            if ([RECORD_OWNER].indexOf(status) > -1) {
                setValue('first_name', null);
                setValue('middle_name', null);
                setValue('last_name', null);
                setValue('email', null);
                setValue('mobile_phone', null);
                setValue('nationality', null);
                setValue('address_1', null);
                setValue('address_2', null);
                setValue('address_3', null);
                setValue('country', null);
                setValue('city', null);
                setValue('state', null);
                setValue('postal_code', null);
            }
            setValue("trust.trust_country", "");
            CLIENT_REQUIRED_FIELDS.map((value) => {
                manageRequireFields(value, getValues(`${value}`));
            });
        }
    }

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        });
    }

    return (
        <div className="form-container panel-form">
            {sellerType !== RECORD_OWNER &&
                <>
                    {menuType == 'invitation' &&
                        <FormRow>
                            <FormControlLabel
                                style={{ display: 'flex', justifyContent: 'end' }}
                                value="end"
                                control={
                                    <Checkbox
                                        checked={isHideFromOtherBroker}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                        onChange={() => { setIsHideFromOtherBroker(!isHideFromOtherBroker) }}
                                    />
                                }
                                label="Hide info from other broker?"
                            />
                        </FormRow>
                    }
                    <FormRow>
                        <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
                    </FormRow>
                </>
            }

            {error &&
                <div className="uk-alert uk-alert-danger" data-uk-alert>
                    <p>{error}</p>
                </div>
            }

            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <label className="uk-form-label">Title *</label>
                    <div className="radio-toolbar">
                        <FormInputWrapper>
                            <input type="radio" name="record_owner" id="record_owner" checked={sellerType === RECORD_OWNER}
                                onChange={(evt) => {
                                    handleSeller(RECORD_OWNER);
                                    setIsOwnerOrLegal(2);
                                }} />
                            <label htmlFor="record_owner" className="uk-form-label radio">Owner of Record</label>
                        </FormInputWrapper>

                        <FormInputWrapper>
                            <input type="radio" name="corporate_owned" id="corporate_owned" checked={sellerType === CORPORATE_OWNED}
                                onChange={(evt) => handleSeller(CORPORATE_OWNED)} />
                            <label htmlFor="corporate_owned" className="uk-form-label radio">Corporate Owned</label>
                        </FormInputWrapper>

                        <FormInputWrapper>
                            <input type="radio" name="llc_owned" id="llc_owned" checked={sellerType === LLC_OWNED}
                                onChange={(evt) => handleSeller(LLC_OWNED)} />
                            <label htmlFor="llc_owned" className="uk-form-label radio">LLC Owned</label>
                        </FormInputWrapper>

                        <FormInputWrapper>
                            <input type="radio" name="legal_owner" id="legal_owner" checked={sellerType === LEGAL_OWNER}
                                onChange={(evt) => {
                                    handleSeller(LEGAL_OWNER);
                                    setIsOwnerOrLegal(1);
                                }} />
                            <label htmlFor="legal_owner" className="uk-form-label radio">Legal Name</label>
                        </FormInputWrapper>

                        <FormInputWrapper>
                            <input type="radio" name="trust_owned" id="trust_owned" checked={sellerType === TRUST_OWNED}
                                onChange={(evt) => handleSeller(TRUST_OWNED)} />
                            <label htmlFor="trust_owned" className="uk-form-label radio">Trust Owned</label>
                        </FormInputWrapper>

                        <FormInputWrapper>
                            <input type="radio" name="joint_owner" id="joint_owner" checked={sellerType === JOINT_OWNER}
                                onChange={(evt) => {
                                    handleSeller(JOINT_OWNER);
                                    setIsOwnerOrLegal(1);
                                }} />
                            <label htmlFor="joint_owner" className="uk-form-label radio">Joint Owner</label>
                        </FormInputWrapper>
                    </div>
                </FormRow>

                {sellerType !== RECORD_OWNER
                    ? <>
                        {[LEGAL_OWNER, JOINT_OWNER].indexOf(sellerType) > -1 &&
                            <>
                                <FormRow>
                                    <FormInputWrapper label={isOwnerOrLegal === 1 ? "First Name *" : "First Name"}>
                                        <input value={getValues('first_name')}
                                            className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                                            {...register('first_name', {
                                                onChange: e => {
                                                    manageRequireFields('first_name', e.target.value);
                                                }
                                            })} type="text" />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Middle Name">
                                        <input className="uk-input" type="text" {...register("middle_name")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label={isOwnerOrLegal === 1 ? "Last Name *" : "Last Name"}>
                                        <input value={getValues('last_name')}
                                            className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                                            {...register('last_name', {
                                                onChange: e => {
                                                    manageRequireFields('last_name', e.target.value);
                                                }
                                            })} type="text" />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label={isOwnerOrLegal === 1 ? "Email *" : "Email"}>
                                        <input value={getValues('email')}
                                            className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                                            {...register('email', {
                                                onChange: e => {
                                                    manageRequireFields('email', e.target.value);
                                                }
                                            })} type="email" />
                                    </FormInputWrapper>
                                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                        <label className="uk-form-label" htmlFor="name">{isOwnerOrLegal === 1 ? "Mobile Phone *" : "Mobile Phone"}</label>
                                        <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                                        <PhoneInput
                                            placeholder='+'
                                            country={'us'}
                                            inputClass={classes.phone_input}
                                            containerClass={classes.phone_container}
                                            autoFocus={false}
                                            onFocus={() => { }}
                                            inputStyle={{
                                                background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                                                borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                                            }}
                                            buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                                            value={getValues("mobile_phone")}
                                            onChange={phone => {
                                                setValue('mobile_phone', phone, { shouldValidate: true })
                                                manageRequireFields('mobile_phone', phone);
                                            }} />
                                    </div>
                                    <FormInputWrapper label="Citizen Of">
                                        <input className="uk-input" type="text" {...register("nationality")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Address">
                                        <input className="uk-input" type="text" {...register("address_1")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Address 2">
                                        <input className="uk-input" type="text" {...register("address_2")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Address 3">
                                        <input className="uk-input" type="text" {...register("address_3")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Country">
                                        <select className="uk-select" {...register("country")} defaultValue="">
                                            {Country.map((country) => {
                                                return (
                                                    <option value={country.code} key={country.code}>
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="City">
                                        <input className="uk-input" type="text" {...register("city")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="State">
                                        {isSelectedUSA ? (
                                            <select className="uk-select" {...register("state")}>
                                                <option defaultValue={undefined}></option>
                                                {StatesForUSA.map((state) => {
                                                    return (
                                                        <option value={state.code} key={state.code}>
                                                            {state.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        ) : (
                                            <input className="uk-input" type="text" {...register("state")} />
                                        )}
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Zip/Postal Code">
                                        <input className="uk-input" type="text" {...register("postal_code")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <Divider className={classes.divider} />
                            </>
                        }
                    </>
                    : <FormRow>
                        <FormInputWrapper label="Email">
                            <input
                                className="uk-input"
                                type="email"
                                {...register("email")}
                            />
                        </FormInputWrapper>
                    </FormRow>
                }

                {[JOINT_OWNER].indexOf(sellerType) > -1 &&
                    <>
                        <FormRow className='mb-3'>
                            <FormInputWrapper size="four">
                                <h3>Joint Owner&nbsp;<small>(to enable “Joint Owner” to sign, please add to “Co-Seller” page)</small></h3>
                            </FormInputWrapper>
                        </FormRow>
                    </>
                }

                {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(sellerType) > -1 && (
                    <>
                        <FormRow>
                            <FormInputWrapper size="three">
                                <h3>Corporation/LLC/Trust Owned</h3>
                            </FormInputWrapper>
                        </FormRow>

                        <FormRow>
                            <FormInputWrapper size="one" label="Number of Beneficial Owner">
                                <select
                                    className="uk-select"
                                    {...register("number_of_beneficial_owners")}
                                    onChange={e => setNumberOfBeneficialOwners(Number(e.target.value))}
                                >
                                    {Array.from({ length: MAX_BENEFICIAL_OWNERS }).map((_, index) => {
                                        const ownerIndex = index + 1;
                                        return (
                                            <option value={ownerIndex} key={ownerIndex}>
                                                {ownerIndex}
                                            </option>
                                        );
                                    })}
                                </select>
                            </FormInputWrapper>
                        </FormRow>

                        <FormRow>
                            <FormInputWrapper size="two" label="Corporation/LLC/Trust Name *">
                                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('trust_name') })}
                                    type="text"
                                    {...register("trust.trust_name", {
                                        onChange: e => {
                                            manageRequireFields('trust_name', e.target.value);
                                        },
                                        required: true
                                    })} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Tax ID">
                                <input className="uk-input" type="text" {...register("trust.trust_tax_id")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Address *">
                                <input className="uk-input" type="text" {...register("trust.trust_address")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Unit/Apt">
                                <input className="uk-input" type="text" {...register("trust.trust_unit_apt")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Country">

                                <select className="uk-select" {...register("trust.trust_country")} defaultValue="" >
                                    <option value=""></option>
                                    {Country.map((country) => {
                                        return (
                                            <option value={country.code} key={country.code}>
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="City *">
                                <input className="uk-input" type="text" {...register("trust.trust_city")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="State *">
                                {isSelectedUSA2 ? (
                                    <select className="uk-select" {...register("trust.trust_state")}>
                                        <option defaultValue={undefined}></option>
                                        {StatesForUSA.map((state) => {
                                            return (
                                                <option value={state.code} key={state.code}>
                                                    {state.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : (
                                    <input className="uk-input" type="text" {...register("trust.trust_state")} />
                                )}
                            </FormInputWrapper>
                            <FormInputWrapper label="Zip">
                                <input className="uk-input" type="text" {...register("trust.trust_zip")} />
                            </FormInputWrapper>
                        </FormRow>

                        <FormRow>

                            <FormInputWrapper label="Owner in good standing">
                                <div className="radio-toolbar">
                                    <input
                                        type="radio"
                                        id="radioStandingYes"
                                        name="radioStanding"
                                        checked={isOwnerInGoodStanding}
                                        onChange={(e) => setValue('trust.trust_is_good_standing', e.target.checked)}
                                    />
                                    <label className="uk-form-label radio">Yes</label>
                                    <input
                                        type="radio"
                                        id="radioStandingNo"
                                        name="radioStanding"
                                        checked={!isOwnerInGoodStanding}
                                        onChange={(e) => setValue('trust.trust_is_good_standing', !e.target.checked)}
                                    />
                                    <label className="uk-form-label radio">No</label>
                                </div>
                            </FormInputWrapper>
                            <FormInputWrapper label="Jurisdiction of Owner">
                                <input className="uk-input" type="text" {...register("trust.trust_jurisdiction_of_owner")} />
                            </FormInputWrapper>
                        </FormRow>


                        <FormRow>
                            <FormInputWrapper label="Name of Signer *">
                                <input className="uk-input" type="text" {...register("trust.trust_signer_name")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Title of Signer *">
                                <input className="uk-input" type="text" {...register("trust.trust_signer_title")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                <label className="uk-form-label" htmlFor="name">Phone</label>
                                <input type="hidden" required name="trust.trust_signer_phone"  {...register("trust.trust_signer_phone")} />
                                <PhoneInput
                                    placeholder='+'
                                    country={'us'}
                                    inputClass={classes.phone_input}
                                    containerClass={classes.phone_container}
                                    autoFocus={false}
                                    onFocus={() => { }}
                                    inputStyle={{
                                        background: `${requiredFields.includes('trust_signer_phone') ? '#fff7f8' : ''}`,
                                        borderColor: `${requiredFields.includes('trust_signer_phone') ? '#dc8d99' : ''}`
                                    }}
                                    buttonStyle={{ borderColor: `${requiredFields.includes('trust_signer_phone') ? '#dc8d99' : ''}` }}
                                    value={getValues("trust.trust_signer_phone")}
                                    onChange={phone => {
                                        manageRequireFields('trust_signer_phone', phone);
                                        setValue("trust.trust_signer_phone", phone, { shouldValidate: true })
                                    }}
                                />
                            </div>
                            <FormInputWrapper label="Email">
                                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('trust_signer_email') })}
                                    type="text"
                                    {...register("trust.trust_signer_email", {
                                        onChange: e => {
                                            manageRequireFields('trust_signer_email', e.target.value);
                                        }
                                    })} />
                            </FormInputWrapper>
                        </FormRow>

                        <FormRow>
                            <FormInputWrapper size="four">
                                <h3>Additional Beneficial Owner{numberOfBeneficialOwners > 1 ? "(s)" : ""}</h3>
                            </FormInputWrapper>
                        </FormRow>

                        {numberOfBeneficialOwners == 1
                            ? <>
                                <FormRow key={`beneficial_owner_1`} size="four">
                                    <FormInputWrapper label={`Beneficial Owner Name *`} size="one">
                                        <input className="uk-input" type="text" {...register(`beneficial_owners.name_1`)} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label={`Beneficial Owner Title *`} size="one">
                                        <input className="uk-input" type="text" {...register(`beneficial_owners.title_1`)} />
                                    </FormInputWrapper>
                                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                        <label className="uk-form-label" htmlFor="name">{`Beneficial Owner Phone *`}</label>
                                        <input type="hidden" required name={`beneficial_owners.phone_1`}  {...register("beneficial_owners.phone_1")} />
                                        <PhoneInput
                                            placeholder='+'
                                            country={'us'}
                                            inputClass={classes.phone_input}
                                            containerClass={classes.phone_container}
                                            autoFocus={false}
                                            onFocus={() => { }}
                                            value={getValues("beneficial_owners.phone_1")}
                                            onChange={phone => { setValue("beneficial_owners.phone_1", phone, { shouldValidate: true }) }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label={`Beneficial Owner Email *`} size="one">
                                        <input className="uk-input" type="text" {...register(`beneficial_owners.email_1`)} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label={`Beneficial Owner Address *`} size="one">
                                        <input className="uk-input" type="text" {...register(`beneficial_owners.address_1`)} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Beneficial Owner Unit/Apt *" size="one">
                                        <input className="uk-input" type="text" {...register("beneficial_owners.unit_apt_1")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Beneficial Owner City *" size="one">
                                        <input className="uk-input" type="text" {...register("beneficial_owners.city_1")} />
                                    </FormInputWrapper>

                                    <FormInputWrapper label="Beneficial Owner State *" size="one">
                                        <input className="uk-input" type="text" {...register("beneficial_owners.state_1")} />
                                    </FormInputWrapper>

                                    <FormInputWrapper label="Beneficial Owner Country *" size="one">
                                        <input className="uk-input" type="text" {...register("beneficial_owners.country_1")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Beneficial Owner Zip Code *" size="one">
                                        <input className="uk-input" type="text" {...register("beneficial_owners.postal_code_1")} />
                                    </FormInputWrapper>
                                </FormRow>
                            </>
                            : (Array.from({ length: numberOfBeneficialOwners }).map((_, index) => {
                                const realIndex = index + 1;
                                return (
                                    <>
                                        <FormRow key={`beneficial_owner_${index}`} size="four">
                                            <FormInputWrapper label={`Beneficial Owner ${realIndex} Name *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.name_${realIndex}`)} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label={`Beneficial Owner ${realIndex} Title *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.title_${realIndex}`)} />
                                            </FormInputWrapper>
                                            <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                                <label className="uk-form-label" htmlFor="name">{`Beneficial Owner ${realIndex} Phone *`}</label>
                                                <input type="hidden" required name={`beneficial_owners.phone_${realIndex}`}  {...register(`beneficial_owners.phone_${realIndex}`)} />
                                                <PhoneInput
                                                    placeholder='+'
                                                    country={'us'}
                                                    inputClass={classes.phone_input}
                                                    containerClass={classes.phone_container}
                                                    autoFocus={false}
                                                    onFocus={() => { }}
                                                    value={getValues(`beneficial_owners.phone_${realIndex}`)}
                                                    onChange={phone => { setValue(`beneficial_owners.phone_${realIndex}`, phone, { shouldValidate: true }) }}
                                                />
                                            </div>
                                            <FormRow>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} Email *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.email_${realIndex}`)} />
                                                </FormInputWrapper>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} Address *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.address_${realIndex}`)} />
                                                </FormInputWrapper>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} Unit/Apt *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.unit_apt_${realIndex}`)} />
                                                </FormInputWrapper>
                                            </FormRow>
                                            <FormRow>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} City *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.city_${realIndex}`)} />
                                                </FormInputWrapper>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} State *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.state_${realIndex}`)} />
                                                </FormInputWrapper>

                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} Country *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.country_${realIndex}`)} />
                                                </FormInputWrapper>
                                            </FormRow>
                                            <FormRow>
                                                <FormInputWrapper label={`Beneficial Owner ${realIndex} Zip Code *`} size="one">
                                                    <input className="uk-input" type="text" {...register(`beneficial_owners.postal_code_${realIndex}`)} />
                                                </FormInputWrapper>
                                            </FormRow>
                                        </FormRow>
                                        <Divider className={classes.divider} />
                                    </>
                                );
                            }))
                        }
                    </>
                )}

                {sellerType !== RECORD_OWNER &&
                    <FormRow>
                        <div className={`one-column mt-3`}>
                            <input className="uk-checkbox " type="checkbox" checked={isGlobal} onChange={() => setIsGlobal(!isGlobal)} />
                            <label className={`uk-form-label font-weight-bold`}>Save CRM Client?</label>
                        </div>
                    </FormRow>
                }

                <FormRow>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormSeller;