import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai-location';

import ViewVesselForm from "./layouts/forms/ViewVessel";
import ViewOfferForm from "./layouts/forms/ViewOffer";
import ViewBuyerForm from "./layouts/forms/ViewBuyer";
import ViewCoBrokerForm from "./layouts/forms/ViewCoBroker";
import ViewSellerForm from "./layouts/forms/ViewSeller";
import ViewAddendumForm from "./layouts/forms/ViewAddendum";
import ViewExclusionsForm from "./layouts/forms/ViewExclusions";
import ViewPurchaseAndSale from "./layouts/forms/ViewPurchaseAndSale";
import SideOffer from "./layouts/accordions/SideOffer";
import SideVessel from "./layouts/accordions/SideVessel";
import SideBuyer from "./layouts/accordions/SideBuyer";
import SideCobroker from "./layouts/accordions/SideCobroker";
import SideSeller from "./layouts/accordions/SideSeller";
import SideToys from "./layouts/accordions/SideToys";
import SideAddendum from "./layouts/accordions/SideAddendum";
import SideExclusions from "./layouts/accordions/SideExclusions";
import UploadDocument from "./layouts/forms/UploadDocument";
import DeleteConfirm from "./layouts/forms/DeleteConfirm";
import UpdateTaskStatus from "./layouts/forms/UpdateTaskStatus";
import EdocumentsViewTable from "./layouts/tables/EdocumentsViewTable";
import UploadViewTable from "./layouts/tables/UploadViewTable";
import UploadEditTable from "./layouts/tables/UploadEditTable";
import TaskViewTable from "./layouts/tables/TaskViewTable";
import TaskEditTable from "./layouts/tables/TaskEditTable";
import NavButtonsWrapper from "./layouts/wrappers/NavButtonsWrapper";
import ViewToysForm from "./layouts/forms/ViewToys";
import AddFormTask from "./layouts/forms/AddFormTask";
import FormViewTable from "./layouts/tables/FormViewTable";
import FormEditTable from "./layouts/tables/FormEditTable";
import Activities from "./Activities";

import PageSidebarContainer from "../../common/PageSidebarContainer";
import PageMainContainer from "../../common/PageMainContainer";
import TransactionDetails from "../../common/TransactionDetails";
import TransactionDetailContent from "../../common/TransactionDetailContent";
import ThemeButton from "../../common/buttons/ThemeButton";
import { Tab, Tabs } from '@mui/material';

import {
  getEdocCompanyTaskList,
  getTransactionEdocById,
  getTransactionEsignById,
  getTransactionInitialById,
  reOrderTasks,
  setAppliedTagFilter,
} from "../../../redux/actions/transaction";
import { getTaskByChecklistId, getChecklist } from "../../../redux/actions/document_tasks";
import { setLoadingStatus } from "../../../redux/actions/loading";
import Signatures from "./layouts/forms/Signatures";
import "./MainView.css"
import DocumentAndTaskService from "../../../services/DocumentAndTaskService";
import FormDataSideBar from "./layouts/FormDataSideBar";
import { DebounceInput } from "react-debounce-input";
import ParticipantsTable from "./layouts/tables/ParticipantsTable";
import SelectParticipantModal from "../../common/SelectParticipantModal";
import { isParticipant } from "../../../utils/permission";
import { toast } from "react-toastify";
import TransactionService from "../../../services/TransactionService";
import AddEsignCustomDocModal from "../../common/AddEsignCustomDocModal";

const tabs = [
  { name: 'Prep Docs', tab: 'prepDocs' },
  { name: 'Signatures', tab: 'signatures' },
  { name: 'Uploads', tab: 'uploads' },
  { name: 'Tasks', tab: 'tasks' },
  { name: 'Forms', tab: 'forms' },
  { name: 'Participants', tab: 'participants' },
]

const tabAtom = atomWithHash('tab', tabs[0].tab);

const DealMainView = (props) => {
  const location = useLocation()
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const checklist = useSelector((state) => state.documents.checklist);
  const allTasks = useSelector((state) => state.documents.tasks);
  const deals = useSelector((state) => state.transactions);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [uploadFilter, setUploadFilter] = useState(null);
  const [taskFilter, setTaskFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState('');
  const [companytagList, setCompanytagList] = useState([]);

  const dataDeal = dataTransaction || {};
  const dataOffer = dataDeal.t_offers;
  const dataExclusions = dataDeal.t_exclusion;
  const dataAmmendment = dataDeal.t_addendum;
  const dataToys = dataDeal.t_toys || [];
  const dataTasks = dataDeal.t_task_master || [];
  const dataEdocuments = dataDeal.t_edocuments || [];

  const [tab, setTab] = useAtom(tabAtom);
  const [isUploadView, setIsUploadView] = useState(true);

  // dataForUpdateUploadListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateUploadListOrder, setDataForUpdateUploadListOrder] = useState([]);

  const [isTaskView, setIsTaskView] = useState(true);

  // dataForUpdateTaskListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateTaskListOrder, setDataForUpdateTaskListOrder] = useState([]);

  const [isFormView, setIsFormView] = useState(true);

  // dataForUpdateFormListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateFormListOrder, setDataForUpdateFormListOrder] = useState([]);

  const [updatingTask, setUpdatingTask] = useState(null);
  const [predocumentType, setPredocumentType] = useState(null);
  const [checklistId, setChecklistId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [statusModalShow, setStatusModalShow] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [closeParticipant, setCloseParticipant] = useState(false);
  const [tags, setTags] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const isFetchingdoc = useRef(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const company_id = user.company_id;
  let { path } = useRouteMatch();
  const listLink = path.split("/").slice(1)[0]

  useEffect(() => {
    if (location.state && location.state.active) {
      setTab(location.state.active);
      let state = location.state;
      delete state.active;
      history.replace({ ...history.location, state });
    }

    if (!checklist.length) { dispatch(getChecklist()) }
  }, []);

  useEffect(() => {
    setTagFilter(dataDeal.applied_filter_tag_id || '');
  }, [dataDeal.applied_filter_tag_id]);

  const getCompanyTagList = useCallback(async () => {
    try {
      const response = await DocumentAndTaskService.getCompanyTagList(company_id);
      setCompanytagList(response.data?.data);
    } catch (error) {
      console.error("Error=> ", error);
    }
  }, []);
  useEffect(() => {
    getCompanyTagList();
  }, [])

  // useEffect to get transaction data by which vessel name and other required data will set
  useEffect(() => {
    if (["prepDocs", "edocuments", "signatures", "participants"].includes(tab)) {

      dispatch(setLoadingStatus(true));
      dispatch(getTransactionInitialById(id)).then(res => {
        dispatch(getTransactionEdocById(id, props.menuType === 'invitation', res.broker?.company_id))
          .then((res) => {
            // dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(id)).then(res => {
              dispatch(setLoadingStatus(false));
            })
              .catch(() => {
                dispatch(setLoadingStatus(false));
              })
          }).catch(() => {
            dispatch(setLoadingStatus(false));
          })
      }).catch(() => {
        dispatch(setLoadingStatus(false));
        history.push("/deals");
      });
    } else {
      dispatch(setLoadingStatus(true));
      dispatch(getTransactionEsignById(id)).then((res) => { dispatch(setLoadingStatus(false)); }).catch(() => { dispatch(setLoadingStatus(false)); });
    }
  }, [id, tab]);

  useEffect(() => {
    const deal = checklist.find(entry => entry.short_name === 'deal');
    if (deal) {
      setChecklistId(deal.id);
      dispatch(getTaskByChecklistId(deal.id, 'iyba', company_id));
      dispatch(getEdocCompanyTaskList(company_id, id));
    }
  }, [checklist]);

  // updateTaskAndUploadListOrder function made for call updateTaskAndUploadListOrder's api which will update sortOrder as per change
  const updateTaskAndUploadListOrder = async (data) => {
    dispatch(setLoadingStatus(true));
    try {
      await dispatch(reOrderTasks(data));
      // dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // updateFormListOrder function made for call updateFormListOrder's api which will update sortOrder as per change
  const updateFormListOrder = async (data) => {
    dispatch(setLoadingStatus(true));
    try {
      await TransactionService.updateSortOrederForm(data);
      // dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // function for handle click of updateUploadListOrder
  const handleUpdateUploadListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateTaskAndUploadListOrder's Api
    if ((!isCancel) && dataForUpdateUploadListOrder.length > 0) {
      await updateTaskAndUploadListOrder(dataForUpdateUploadListOrder);
    }
    setIsUploadView(true);
    setDataForUpdateUploadListOrder([]);
  }

  // function for handle click of updateTaskListOrder
  const handleUpdateTaskListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateTaskAndUploadListOrder's Api
    if ((!isCancel) && dataForUpdateTaskListOrder.length > 0) {
      await updateTaskAndUploadListOrder(dataForUpdateTaskListOrder);
    }
    setIsTaskView(true);
    setDataForUpdateTaskListOrder([]);
  }

  // function for handle click of updateFormListOrder
  const handleUpdateFormListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateFormListOrder's Api
    if ((!isCancel) && !isFormView && dataForUpdateFormListOrder.length > 0) {
      await updateFormListOrder(dataForUpdateFormListOrder);
    }
    setIsFormView(!isFormView);
    setDataForUpdateFormListOrder([]);
  }

  const handleFilterTagChange = (tagId) => {
    const filter = {
      applied_filter_tag_id: tagId || null
    };
    dispatch(setLoadingStatus(true));
    dispatch(setAppliedTagFilter(id, filter))
      .then(
        response => {
          setTagFilter(response.id || '');
          dispatch(setLoadingStatus(false));
        })
      .catch(
        error => {
          setTagFilter('');
          dispatch(setLoadingStatus(false));
        })
  }
  const handleFilterTagOfSignatureChange = (value) => {
    setTags(value)
  }
  const dataDocument = dataTasks.filter((task) => {
    if (uploadFilter) {
      return task.task_type === "document" && task.status == uploadFilter;
    }
    return task.task_type === "document";
  }).filter(task => {
    if (tagFilter && task.company_tasks?.create_type === 'global') {
      if (task.tags[0] == null) {
        return false;
      }
      return task.task_type === "document" && !!task.tags.find(tag => tag.id == tagFilter);
    }
    return task.task_type === "document";
  })
  // }).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

  const dataTask = dataTasks.filter((task) => {
    if (taskFilter) {
      return task.task_type === "task" && task.status == taskFilter
    }
    return task.task_type === "task"
  }).filter(task => {
    if (tagFilter && task.company_tasks?.create_type === 'global') {
      if (task.tags[0] == null) {
        return false;
      }
      return task.task_type === "task" && !!task.tags.find(tag => tag.id == tagFilter);
    }
    return task.task_type === "task";
  })
  // }).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

  let dataForm = dataTasks.filter((task) => task.task_type === "form").sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);

  const titles = {
    draft: "DRAFT",
    progress: "IN-PROCESS",
    active: 'ACTIVE',
    cancelled: "CANCELLED",
    completed: "SOLD",
    rejected: "REJECTED",
    archived: "ARCHIVED"
  };

  const addAddendum = () => {
    let url = history.location.pathname.replace("main", "purchase-&-sale-agreement-addendum");
    history.push(url);
  };

  const addExclusion = () => {
    let url = history.location.pathname.replace("main", "purchase-&-sale-agreement-exclusion");
    history.push(url);
  }

  const renderForms = () => {
    return (
      <>
        <ViewOfferForm offer={dataOffer} />
        <ViewVesselForm vessel={dataDeal.t_vessel} />
        <ViewBuyerForm
          buyer={dataDeal.buyer}
          cobuyer={dataDeal.co_buyer}
          type={dataDeal.buyer_type}
        />
        <ViewCoBrokerForm cobroker={dataDeal.co_broker} offers={dataDeal.t_offers} />
        <ViewSellerForm
          seller={dataDeal.seller}
          coseller={dataDeal.co_seller}
          type={dataDeal.seller_type}
        />
        <ViewAddendumForm addendum={dataAmmendment} />
        <ViewExclusionsForm exclusions={dataExclusions} />
        <ViewToysForm toys={dataToys} />
        <ViewPurchaseAndSale />
      </>
    );
  };

  const getCompanyTaskList = () => {
    dispatch(setLoadingStatus(true));
    dispatch(getEdocCompanyTaskList(company_id, id, updatingTask.sortOrder ? { sortOrder: updatingTask.sortOrder } : {}))
      .then((response) => {
        dispatch(setLoadingStatus(false));
      }).catch((error) => {
        dispatch(setLoadingStatus(false));
      });
  }

  const renderUploadTabForms = () => {
    return (
      <div>
        <UploadDocument
          transactionId={dataDeal.id}
          sortOrder={dataTasks.length}
          predocumentType={predocumentType}
          companyId={company_id}
          show={modalShow}
          onClose={() => setModalShow(false)}
          uniqueId={dataDeal?.unique_transaction_id && `PS-${dataDeal.unique_transaction_id?.split('-')[1] || ""}`}
          companyUId={dataDeal?.unique_transaction_id && `${dataDeal.unique_transaction_id?.split('-')[0] || ""}`}
        />
        <UpdateTaskStatus task={updatingTask} transactionId={id} show={statusModalShow} onClose={(refreshList = false) => { setStatusModalShow(false); setUpdatingTask(null); if (refreshList) { getCompanyTaskList() }; }} />
      </div>
    );
  };

  const renderPrefillTabForms = () => {
    return (
      <AddFormTask
        transactionId={dataDeal.id}
        checklistId={checklistId}
        formList={allTasks.filter(task => task.task_type === 'form')}
        data={dataForm}
      />
    )
  }

  /**
   * State for filter the documents by category and search document name
   */
  const [search, setSearch] = useState({
    category: 'all',
    name: ''
  });

  /**
   * On change category tab and filter by selected category
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleChange = (event, newValue) => {
    setTags('')
    setSearch((state) => {
      return {
        ...state,
        category: newValue
      }
    })
  };

  const handleEsignCustomDocModal = () => {
    setOpenModal(true);
  }

  const handleEsignCustomDocModalClose = () => {
    setOpenModal(false);
  }

  /**
   * Render Tab for category wise documents and a search filter
   * @returns 
   */
  const renderFormsHeader = () => {
    return (
      <>
        <div className="forms-nav-actions  sm-full-width">
          <button className={`uk-button ${!isFormView ? "uk-button-primary" : "uk-button-default"}`}
            style={isFormView ? { width: "6vw", margin: "0px" } : { margin: "0px" }}
            onClick={() => handleUpdateFormListOrder()}>{isFormView ? 'Edit Sort' : 'Save Sort'}</button>
          {!isFormView && <button className="uk-button uk-button-orange" onClick={() => handleUpdateFormListOrder(true)}>Cancel</button>}
          {isFormView &&
            <>
              <div className="tab-block max-w-100">
                <Tabs value={search.category} onChange={handleChange} indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons={"auto"}
                  allowScrollButtonsMobile
                >
                  <Tab value="all" label="All" />
                  <Tab value="agreements" label="Agreements" />
                  <Tab value="closing_docs" label="Closing Docs" />
                  <Tab value="state_reg" label="State Reg" />
                  <Tab value="documentation" label="Documentation" />
                </Tabs>
              </div>
              <div className="list-page-search-container search-block d-flex">
                <div className="two-column uk-padding-small d-flex">
                  <button className="uk-button uk-button-light" style={{
                    lineHeight: "10px",
                    padding: "0px 20px",
                    fontSize: "smaller"
                  }} onClick={() => {
                    handleEsignCustomDocModal();
                  }}><i className="fa fa-upload" aria-hidden="true"></i> Custom Document</button>
                </div>
                <div className="one-column uk-padding-small ">
                  <select className="uk-select"
                    onChange={(e) => handleFilterTagOfSignatureChange(e.target.value)}
                    value={tags}
                  >
                    <option key={`status_default`} value="">Select a Tag</option>
                    {tagList?.map(tag => (
                      <option key={tag} value={tag}>{tag}</option>
                    ))}
                  </select>
                </div>
                <form className="uk-search uk-search-default">
                  <DebounceInput
                    className="uk-search-input"
                    type="search"
                    placeholder="Search"
                    onChange={(e) => setSearch((state) => {
                      return {
                        ...state,
                        name: e.target.value
                      }
                    })}
                    value={search.name || ''}
                    debounceTimeout={500}
                    minLength={1}
                  />
                  {search.name === "" ? (
                    <span className="bd-form-icon">
                      <i className="fa fa-search"></i>
                    </span>
                  ) : (
                    <span className="bd-form-icon" onClick={() => setSearch(state => {
                      return {
                        ...state,
                        name: ""
                      }
                    })}>
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                </form>
              </div>
            </>
          }
        </div>
      </>
    )
  }

  const renderTabContent = () => {
    return (
      <>
        {/* when new deal creates tab will edocuments so that time we need prepDocs tab  */}
        {["prepDocs", "edocuments"].includes(tab) && (
          <>
            <EdocumentsViewTable data={dataEdocuments} setTab={setTab} status={dataDeal.status} menuType={props.menuType} />
            {/* <div style={{ marginTop: "20px" }}>
              <ThemeButton onClick={addExclusion}>Add Exclusion List</ThemeButton>
              <ThemeButton onClick={addAddendum}>Add Addendum</ThemeButton>
            </div> */}
          </>
        )}
        {tab === "uploads" && listLink == "deals" && (
          <>
            <NavButtonsWrapper>
              {isUploadView ? (
                <div className="d-flex flex-wrap">
                  <ThemeButton onClick={() => { setPredocumentType(null); setModalShow(true); }}>
                    Add Document
                  </ThemeButton>
                  <ThemeButton onClick={() => { setIsUploadView(false); setDataForUpdateUploadListOrder([]); }}>Edit Sort</ThemeButton>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => setUploadFilter(e.target.value)}>
                      <option key={`status_default`} value="">Select a Status</option>
                      <option key="open" value="open">Open</option>
                      <option key="Required" value="required">Required</option>
                      <option key="Received" value="received">Received</option>
                      <option key="completed" value="not_available">N/A</option>
                    </select>
                  </div>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => handleFilterTagChange(e.target.value)} value={tagFilter}>
                      <option key={`status_default`} value="">Select a Tag</option>
                      {companytagList.map(tag => (
                        <option key={tag.id} value={tag.id}>{tag.tag}</option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <>
                  <ThemeButton theme={"primary"} onClick={() => handleUpdateUploadListOrder()}>Save Sort</ThemeButton>
                  <ThemeButton theme={"orange"} onClick={() => handleUpdateUploadListOrder(true)}>Cancel</ThemeButton>
                </>
              )}
            </NavButtonsWrapper>
            {isUploadView
              ? <UploadViewTable
                data={dataDocument}
                show={isUploadView}
                setPredocumentType={setPredocumentType}
                setShowModal={setModalShow}
                setStatusShowModal={setStatusModalShow}
                setUpdatingTask={setUpdatingTask}
                companyId={company_id}
                transactionId={id}
                getTransactionEdoc={() => dispatch(getTransactionEdocById(id, props.menuType === 'invitation', dataDeal.broker?.company_id))}
              />
              : <UploadEditTable show={!isUploadView} companyId={company_id} setDataForUpdateUploadListOrder={setDataForUpdateUploadListOrder} />
            }
          </>
        )}
        {tab === "tasks" && listLink == "deals" && (
          <>
            <NavButtonsWrapper>
              {isTaskView ? (
                <div className="d-flex flex-wrap">
                  <ThemeButton onClick={() => { setIsTaskView(false); setDataForUpdateTaskListOrder([]); }}>Edit Sort</ThemeButton>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => setTaskFilter(e.target.value)}>
                      <option key={`tasks_default`} value="">Select a Status</option>
                      <option key="not_started" value="not_started">Not Started</option>
                      <option key="in_process" value="in_process">In Process</option>
                      <option key="completed" value="completed">Completed</option>
                      <option key="completed" value="not_available">N/A</option>
                    </select>
                  </div>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => handleFilterTagChange(e.target.value)} value={tagFilter}>
                      <option key={`status_default`} value="">Select a Tag</option>
                      {companytagList.map(tag => (
                        <option key={tag.id} value={tag.id}>{tag.tag}</option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <>
                  <ThemeButton theme={"primary"} onClick={() => handleUpdateTaskListOrder()}>Save Sort</ThemeButton>
                  <ThemeButton theme={"orange"} onClick={() => handleUpdateTaskListOrder(true)}>Cancel</ThemeButton>
                </>
              )}
            </NavButtonsWrapper>
            {isTaskView
              ? <TaskViewTable data={dataTask} show={isTaskView} setStatusShowModal={setStatusModalShow} setUpdatingTask={setUpdatingTask} companyId={company_id} transactionId={id} />
              : <TaskEditTable show={!isTaskView} transactionId={id} companyId={company_id} setDataForUpdateTaskListOrder={setDataForUpdateTaskListOrder} />
            }
          </>
        )}
        {tab === "signatures" && (
          <Signatures menuType={props.menuType} />
        )}
        {tab === "forms" && !isParticipant() && listLink == "deals" && (
          <>
            {renderFormsHeader()}
            {isFormView && <FormViewTable searchProp={search} deal={dataDeal} tags={tags} openModal={isFetchingdoc} setTagList={setTagList} />}
            {!isFormView && <FormEditTable setDataForUpdateFormListOrder={setDataForUpdateFormListOrder} />}
          </>
        )}
        {tab === "participants" && !isParticipant() && listLink == "deals" &&
          <>
            {renderParticipantHeader()}
            <ParticipantsTable eDocuments={dataEdocuments} onOpenParticipantForm={onOpenParticipantFormData} closeParticipantForm={closeParticipant} />
            {showParticipantModal && <SelectParticipantModal show={showParticipantModal}
              transactionId={dataDeal.id} onClose={onCloseAddParticipantModal} />}
          </>
        }
      </>
    );
  };

  const renderParticipantHeader = () => {
    return (
      <div className="forms-nav-actions">
        <button className="uk-button uk-button-default add-deal" onClick={() => { setShowParticipantModal(true); setCloseParticipant(false); }}>
          <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add
        </button>
      </div >
    )
  }

  const renderActivities = () => {
    if (dataDeal.id) {
      return <Activities transactionId={dataDeal.id} />;
    }
  };

  let title = dataDeal.t_vessel
    ? `${dataDeal.t_vessel.year || ""} ${dataDeal.t_vessel.make || ""} ${dataDeal.t_vessel.model || ""} ${dataDeal.t_vessel.length || ""
      }` !== "   "
      ? `${dataDeal.t_vessel.year || ""} ${dataDeal.t_vessel.make || ""} ${dataDeal.t_vessel.model || ""
      } ${dataDeal.t_vessel.length || ""}`
      : "N/A"
    : "N/A";
  let status = dataDeal.status ? titles[dataDeal.status] : "Draft";

  const links = [
    { label: `${listLink.split('-').join(' ')}`, link: `/${listLink}` },
    { label: title, link: null },
  ];

  /**
   * on Closing add participant modal and refresh data on Participant list
   * @param {*} flag 
   */
  const onCloseAddParticipantModal = (flag) => {
    setShowParticipantModal(false);
    if (flag) {
      setCloseParticipant(true);
    }
  }

  /**
   * Open side panel for particular role when click on Participant Form Data button
   * @param {*} participant 
   */
  const onOpenParticipantFormData = (participant) => {
    setParticipant(participant);
    setCloseParticipant(false);
  }

  /**
   * on Closing Form Data panel for particular role and refresh data on Participant list
   * @param {*} flag 
   */
  const onCloseParticipantForm = (flag) => {
    setParticipant(null);
    if (flag || tab === "participants") {
      setCloseParticipant(false);
      setCloseParticipant(true);
    }
  }

  const transactionUniqueId = dataDeal.unique_transaction_id;

  return (
    <>
      <div>
        <TransactionDetails links={links} title={title} status={status} type={`Back to ${listLink.split('-').join(' ')}`} typeLink={`/${listLink}`} uniqueId={transactionUniqueId && `PS-${transactionUniqueId}`} currentTab={`deal_${tab}`} />
        <FormDataSideBar transactionId={dataTransaction?.id} sliderFor='edocument' reloadPdf={null} participantForm={participant} onPanelClose={onCloseParticipantForm} menuType={props.menuType} />
        {/* <PageSidebarContainer type="transaction-details">
        {renderSideAccordions()}
      </PageSidebarContainer> */}
        <PageMainContainer type="view-deal">
          <div className="uk-position-relative uk-margin-small uk-flex uk-flex-between flex-wrap user-tabs" style={{ maxWidth: '1324px' }}>
            {
              (isParticipant() && (tab === "participants" || tab === "forms")) ? <></> :
                ((listLink !== "deals" && (tab === "participants" || tab === "forms" || tab === "tasks" || tab === "uploads")) ? <></> :
                  <Tabs value={tab} indicatorColor="primary" onChange={(e, val) => setTab(val)}>
                    {tabs.map((tabInfo, idx) => {
                      {/* when new deal creates tab will edocuments so that time we need prepDocs tab  */ }
                      const isSelectedTab = tab === tabInfo.tab;
                      if (isParticipant() && (tabInfo.tab === "participants" || tabInfo.tab === "forms")) {
                        return <></>
                      }
                      if (listLink !== "deals" && (tabInfo.tab === "participants" || tabInfo.tab === "forms" || tabInfo.tab === "tasks" || tabInfo.tab === "uploads")) {
                        return <></>
                      }
                      if (tabInfo.name === "Prep Docs") {
                        return <Tab key={idx} value={tab === "edocuments" ? "edocuments" : "prepDocs"} label="Prep Docs" />
                      }
                      else {
                        return <Tab key={idx} value={tabInfo.tab} label={tabInfo.name} />
                      }
                    })}
                  </Tabs>
                )
            }
            <div className="d-flex gap-2 align-items-center" >

              <a href='https://boathistoryreport.com/services/boat_docs' target='_blank' rel="noreferrer" >
                <img src="/images/BoatHistoryReport_Preserver_high.png" width='30' alt="Boat History Report" />
                &nbsp;
                Boat History Report State & USCG Documents

              </a>
              <a href='https://www.americanvessel.com/' target='_blank' rel="noreferrer">

                <img src="/images/avda.png" width='80' height='35' className="ml-4 pr-2" alt="avda" />
              </a>
            </div>

          </div>
          <TransactionDetailContent
            tabContent={renderTabContent}
            accordionContent={renderActivities}
            tab={tab}
          />
        </PageMainContainer>
        {renderForms()}
        {renderUploadTabForms()}
        {renderPrefillTabForms()}
        <DeleteConfirm />
      </div>
    <AddEsignCustomDocModal show={openModal} onClose={handleEsignCustomDocModalClose} transactionId={dataTransaction?.id}  isFetchingdoc={isFetchingdoc}/>
    </>
  );
};

export default DealMainView;
