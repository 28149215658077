import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./export-user-modal.css";

//exportList in props is function for export the file
const ExportUsersModal = ({
  show,
  onClose,
  tabName,
  exportList,
  showLoader,
}) => {
  //array for userExport fields checkboxes
  const userFields = [
    {
      value: "fullName",
      labelName: "Full Name",
    },
    {
      value: "role",
      labelName: "Role",
    },
    {
      value: "broker",
      labelName: "Broker",
    },
    {
      value: "ebSigns",
      labelName: "EB Signs",
    },
    {
      value: "email",
      labelName: "Email",
    },
    {
      value: "mobile",
      labelName: "Mobile",
    },
    {
      value: "office",
      labelName: "Office",
    },
    {
      value: "company",
      labelName: "Company",
    },
    {
      value: "access",
      labelName: "Access",
    },
    {
      value: "mlsUser",
      labelName: "MLS User",
    },
    {
      value: "mlsOffice",
      labelName: "MLS Office",
    },
    {
      value: "createdDate",
      labelName: "Created Date",
    },
    {
      value: "LA",
      labelName: "LA",
    },
    {
      value: "Deals",
      labelName: "Deals",
    },
  ];

  //array for participantExport fields checkboxes
  const participantFields = [
    {
      value: "fullName",
      labelName: "Full Name",
    },
    {
      value: "email",
      labelName: "Email",
    },
    {
      value: "mobile",
      labelName: "Mobile",
    },
    {
      value: "status",
      labelName: "Status",
    },
    // {
    //   value: "associatedCompanies",
    //   labelName: "Associated Companies",
    // },
  ];
  //array for guest list user fields checkboxes
  const guestAgentFields = [
    {
      value: "fullName",
      labelName: "Full Name",
    },
    {
      value: "email",
      labelName: "Email",
    },
    {
      value: "mobile",
      labelName: "Mobile",
    },
    {
      value: "status",
      labelName: "Status",
    },
  ];

  //schema for validation of checkboxes , at least one checkbox must be selected
  const schema = Yup.object().shape({
    userFields: Yup.array()
      .min(1, "At least one field must be selected")
      .required("This field is required"),
    participantFields: Yup.array()
      .min(1, "At least one field must be selected")
      .required("This field is required"),
    guestAgentFields: Yup.array()
      .min(1, "At least one field must be selected")
      .required("This field is required"),
  });

  //in useForm, given defaultValues for auto selected checkbox fields
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      isExcel: true,
      userFields: [
        "Full Name",
        "Role",
        "Broker",
        "EB Signs",
        "Email",
        "Mobile",
        "Office",
      ],
      participantFields: ["Full Name", "Email", "Mobile", "Status"],
      guestAgentFields: ["Full Name", "Email", "Mobile", "Status"],
    },
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  // this will call on onSubmit and it will handle in List.jsx(parent component)
  const handleRegistration = (formData) => {
    exportList(formData);
  };

  //if any error occurs on submit then print in console
  const handleError = (errors) => {
    console.log(errors);
  };

  //Store `isExcel` field value into the variable.
  const isExcel = useMemo(() => getValues("isExcel"), [watch("isExcel")]);

  return (
    <Modal show={show} onHide={onClose} centered={true} size="lg">
      <Modal.Header closeButton className="py-2">
        <h3 className="my-2">
          Export
          {tabName === "users"
            ? " Users "
            : tabName === "participants"
            ? " Participants "
            : "Guest Agents "}
          Report
        </h3>
      </Modal.Header>
      <form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <Modal.Body className="p-2">
          <div className="position-relative">
            {showLoader && (
              <div className="d-flex justify-content-center align-items-center export-user-modal-loader h-100 w-100 position-absolute">
                <div className="spinner-border text-primary " role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <div className="py-3 px-2 ">
              <div>
                <h4>Choose Required Fields</h4>
                <div className="checkbox-radio-div d-flex flex-wrap ">
                  {tabName === "users"
                    ? // userFields Checkboxes
                      userFields?.map((field, index) => {
                        return (
                          <div
                            key={index}
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              id={field.value}
                              type="checkbox"
                              name={field.value}
                              value={field.labelName}
                              {...register("userFields")}
                            />
                            <label
                              className="form-check-label"
                              for={field.value}
                            >
                              {field.labelName}
                            </label>
                          </div>
                        );
                      })
                    : tabName === "participant"
                    ? // participantFields Checkboxes

                      participantFields?.map((field, index) => {
                        return (
                          <div
                            key={index}
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              id={field.value}
                              type="checkbox"
                              disabled={field.value === "associatedCompanies"}
                              name={field.value}
                              value={field.labelName}
                              {...register("participantFields")}
                            />
                            <label
                              className="form-check-label"
                              for={field.value}
                            >
                              {field.labelName}
                            </label>
                          </div>
                        );
                      })
                    : guestAgentFields?.map((field, index) => {
                        return (
                          <div
                            key={index}
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              id={field.value}
                              type="checkbox"
                              disabled={field.value === "associatedCompanies"}
                              name={field.value}
                              value={field.labelName}
                              {...register("guestAgentFields")}
                            />
                            <label
                              className="form-check-label"
                              for={field.value}
                            >
                              {field.labelName}
                            </label>
                          </div>
                        );
                      })}
                </div>
                {errors.userFields && (
                  <p className="text-danger mt-2 checkbox-radio-div error-div">
                    {errors.userFields.message}
                  </p>
                )}
                {errors.participantFields && (
                  <p className="text-danger">
                    {errors.participantFields.message}
                  </p>
                )}
                {errors.guestAgentFields && (
                  <p className="text-danger">
                    {errors.guestAgentFields.message}
                  </p>
                )}
              </div>

            {/* radio buttons for export file type  */}
            <div className="mt-4">
              <h4 className="mb-2">Export As</h4>
              <div className="checkbox-radio-div">
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    name="excel"
                    type="radio"
                    checked={isExcel}
                    onChange={() => setValue("isExcel", true)}
                  />
                  <label className="form-check-label">Excel</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    name="csv"
                    type="radio"
                    checked={!isExcel}
                    onChange={() => setValue("isExcel", false)}
                  />
                  <label className="form-check-label">CSV</label>
                </div>
              </div>
            </div>
            </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="uk-button uk-button-default uk-modal-close small"
            type="button"
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </button>
          <button className="uk-button uk-button-primary small" disabled={showLoader} type="submit">
            Export
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ExportUsersModal;
