import { useDispatch, useSelector } from "react-redux";

import Moment from "react-moment";
import DataTable from "react-data-table-component";
import clsx from "clsx";

import datatableStyles from "../../../../../utils/datatable-style";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import TransactionService from "../../../../../services/TransactionService";
import { getTransactionEdocById } from "../../../../../redux/actions/transaction";
import StyleSwitch from "../../../../common/buttons/StyleSwitch";

const FormViewTable = ({ tags, setTagList, searchText }) => {

  const dispatch = useDispatch();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const isLoading = useSelector((state) => state.loading.status);


  const dataEdocuments = dataTransaction?.t_edocuments || [];

  const [documents, setDocuments] = useState([]);
  const [transactionEdoc, setTransactionEdoc] = useState([]);
  const [search, setSearch] = useState({});

  const removeNullValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key]
      }
    }
    return obj
  }

  const fetchDocuments = async (transactionId, needEDoc = false, tags = "",) => {
    dispatch(setLoadingStatus(true));
    try {
      const { data } = await TransactionService.fetchAllDocuments(transactionId, removeNullValues({ ...search, tags: tags ?? "" }));
      setDocuments(data.templates);
      setTagList(data.tags);
      setTransactionEdoc(data.transactionEdoc);
      if (needEDoc) {
        dispatch(getTransactionEdocById(dataTransaction?.id)).then((response) => {
          dispatch(setLoadingStatus(false));
        }).catch((error) => {
          dispatch(setLoadingStatus(false));
        });
      } else {
        dispatch(setLoadingStatus(false));
      }
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error while fetching documents.", {
        autoClose: 2000
      })
    }
  }
  useEffect(() => {
    if (searchText?.name || searchText?.name === "") {
      setSearch((search) => {
        return {
          ...search, ...searchText
        }
      })
    }
  }, [searchText])

  useEffect(() => {
    if (dataTransaction && dataTransaction.id) {
      fetchDocuments(dataTransaction.id, false, tags)
    }
  }, [dataTransaction?.id, search])

  useEffect(async () => {
    if (tags || tags === "") {
      await fetchDocuments(dataTransaction.id, false, tags)
    }
  }, [tags])

  const updateUseField = async (row) => {
    const tEdocument = transactionEdoc.filter(edoc => edoc.t_esign_template?.id == row.id);
    let edocId = null;
    if (tEdocument.length > 1) {
      let latestEdoc = tEdocument.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
      if (latestEdoc) {
        if (latestEdoc.is_duplicate) {
          edocId = tEdocument.map(edoc => edoc.id);
        } else {
          edocId = [latestEdoc.id];
        }
      }
    } else {
      edocId = tEdocument[0]?.id ? [tEdocument[0]?.id] : null;
    }
    dispatch(setLoadingStatus(true))
    try {
      let updateData = {
        "use": !row?.use,
        transactionId: dataTransaction.id,
        edocId: edocId
      }
      await TransactionService.updateDocumentUseField(row?.id, updateData);
      await fetchDocuments(dataTransaction?.id, true);
      // dispatch(setLoadingStatus(false))
      toast.success("Document Updated Successfully.", {
        autoClose: 2000
      })
    } catch (error) {
      console.log(error);
      dispatch(setLoadingStatus(false))
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    }
  }

  const getPreview = (documentName, id) => {
    try {
      dispatch(setLoadingStatus(true));
      let id = dataTransaction.id;
      switch (documentName) {
        case "Listing Agreement Addendum":
          const addendumDocs = dataEdocuments.find((doc) => doc.title === "Listing Agreement Addendum" && !doc.is_duplicate);
          id = addendumDocs?.id || id;
          break;

        case "Listing Agreement Exclusion":
          const exclusions = dataEdocuments.find((doc) => doc.title === "Listing Agreement Exclusion" && !doc.is_duplicate);
          id = exclusions?.id || id;
          break;
      }
      TransactionService.getDocumentUrl(documentName)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res?.data.url) {
            window.open(`https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${res.data.url}`, '_blank');
          } else {
            toast.error("Error occurs, please try again.", {
              autoClose: 2000
            })
          }
        })
        .catch(() => {
          dispatch(setLoadingStatus(false));
          toast.error("Error occurs, please try again.", {
            autoClose: 2000
          })
        })
    } catch (error) {
      toast.error("Error, While getting preview of document", {
        autoClose: 2000
      })
    }

  }

  const previewByStep = async (documentName, documentId) => {
    try {
      let id = dataTransaction.id;
      switch (documentName) {
        case "Listing Agreement Addendum":
          const addendumDocs = dataEdocuments.filter((doc) => doc.title === "Listing Agreement Addendum");
          // if (addendumDocs && addendumDocs.length == 0) {
          //   throw new Error("There is no Addendum...");
          // }
          id = addendumDocs[0]?.id || id;
          break;
      }
      await getPreview(documentName, id);
    } catch (error) {
      TransactionService.getDocumentUrl(documentName)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res?.data.url) {
            window.open(`https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${res.data.url}`, '_blank');
          } else {
            toast.error("Error occurs, please try again.", {
              autoClose: 2000
            })
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          toast.error("Error occurs, please try again.", {
            autoClose: 2000
          })
        })
    }
  }

  const obj = {
    "single": "Single Use",
    "multi_select": "Multi Select",
    "multiple": "Multi Use"
  }

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector,
      }
    });
  };

  const columns = [
    {
      name: "Order",
      selector: "sort_order",
      width: "80px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "USE",
      width: "130px",
      center: true,
      cell: (row) => (
        <label className='m-0' >
          <StyleSwitch
            checked={row.use}
            onChange={() => updateUseField(row)}
          />
        </label>
      ),
    },
    {
      name: "Template Name",
      selector: "template_name",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.template?.template_name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Association",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.template?.association || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.template?.checklist?.name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Use Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              obj[row?.template?.use_type] || ""
            }
          </div>
        </div>
      ),
    },
    {
      name: "Hand Signed Only",
      // grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.template?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Tags",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.tags?.join(", ")
            }
          </div>
        </div>
      ),
    },
    {
      name: "View Blank PDF",
      cell: (row) => (
        <div>
          <a onClick={async () => {
            const fileLink = row?.template?.edocument?.title || false;
            if (fileLink) {
              await previewByStep(fileLink, row?.template?.edocument?.id);
              return;
            }
            window.open(`https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${row?.template?.template_link}`, '_blank');
          }}>
            <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
          </a>
        </div>
      ),
    },
  ];
  useEffect(() => {
  }, [documents])

  // if (!show) {
  //   return <></>;
  // }
  return (
    <> {!documents.length && isLoading
      ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
      : <DataTable
        columns={columns}
        data={documents}
        highlightOnHover={true}
        striped={true}
        noHeader={true}
        onSort={handleSort}
        customStyles={datatableStyles}
      />
    }
    </>
  );
};

export default FormViewTable;
