import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getByType = (type, search) => {
  return http.post("/vessel_toys/getByType", { type: type, vessel_name: search });
};

const get = (id) => {
  return http.get(`/vessel_toys/${id}`);
};

const getVessel = (id) => {
  return http.get(`/vessel_toys/global/${id}`);
};

const create = (data) => {
  return http.post("/vessel_toys", data);
};

const update = (id, data) => {
  return http.put(`/vessel_toys/${id}`, data);
};

const updateTransactionVessel = (id, data) => {
  return http.put(`/vessel_toys/transaction_vessel/${id}`, data);
}

const remove = (id) => {
  return http.delete(`/vessel_toys/${id}`);
};

const removeAll = () => {
  return http.delete("/vessel_toys");
};

const findByName = (search, type) => {
  return http.post("/vessel_toys/searchByName", { search: search, type: type });
};

const findByHullNumber = (hullnum, type) => {
  return http.post("/vessel_toys/searchByHullnumber", {
    hullnum: hullnum,
    type: type,
  });
};

const findByHullNumberFromYachtBroker = (hullNumber) => {
  // return {"current_page":1,"first_page_url":"https:\/\/api.yachtbroker.org\/all-vessels?page=1","from":1,"last_page":1,"last_page_url":"https:\/\/api.yachtbroker.org\/all-vessels?page=1","next_page_url":null,"path":"https:\/\/api.yachtbroker.org\/all-vessels","per_page":500,"prev_page_url":"null","to":1,"total":1,"InputArguments":{"hin":"CI"},"V-Data":[{"Status":"On","HullIdentificationNumber":"CIY25045F394","Type":"Sail","Year":1994,"VesselName":"Joker","Manufacturer":"Compass","Model":"Open","DisplayLengthFeet":25,"DisplayLengthMeters":7.62,"FuelType":"Gas\/Petrol","DisplayPicture":"2785670_bb59b353_1.jpg"}],"MetaData":{"RequestTimestamp":"2022-03-04T11:26:29.ESTZ","ResponseTimestamp":"2022-03-04T11:26:29.ESTZ","ResponseGenerationTimeSeconds":0.10197305679321289,"ResponseSizeKB":0.5947265625}};
  return http.post(`/vessel_toys/searchFromBroker`, { hullNumber });
}

const findToysByDeal = (dealId) => {
  return http.get(`/vessel_toys/retrieveByDeal/${dealId}`);
};

const retrieveMlsFilters = (dealId) => {
  return http.get(`/vessel_toys/retrieveMlsFilters`);
};

/**
 * This is use to fetch the list of Vessels
 * @param {ObjectId} userId 
 * @returns 
 */
const retrieveMlsVessel = (userId, searchText, searchByHIN, page, limit) => {
  return http.get(`/vessel_toys/retrieveMlsFilters/${userId}?searchtext=${searchText}&page=${page}&limit=${limit}&is_search_by_hin=${searchByHIN}`,);
};

/**
 * This is use to fetch the list of Vessels
 * @param {ObjectId} userId 
 * @returns 
 */
const retrieveMlsVesselWithoutBroker = (userId, searchText, searchByHIN ,page, limit) => {
  return http.get(`/vessel_toys/retrieveMlsFiltersWithoutBroker/${userId}?searchtext=${searchText}&page=${page}&limit=${limit}&is_search_by_hin=${searchByHIN}`,);
};

/**
 * This is used to fetch data of mls vessel
 * @param {ObjectId} userId 
 * @param {String} vesselId 
 * @returns 
 */
const getMLSVessel = (userId, vesselId, transactionId) => {
  return http.get(`/vessel_toys/retrieveMlsVessel/${userId}/${vesselId}?transactionId=${transactionId || ""}`);
}

/**
 * This is used to send data of mls vessel
 * @param {ObjectId} userId 
 * @param {String} vesselId 
 * @returns 
 */
const createMLSVessel = (userId, vesselId, vesselData) => {
  return http.post(`/vessel_toys/mlsVessel/${userId}/${vesselId}`, vesselData);
}

const getSurveyWaiverData = (vesselId) => {
  return http.get(`/vessel_toys/transactionVesselWaiver/${vesselId}`);
}

const VesselAndToyService = {
  retrieveMlsVesselWithoutBroker,
  createMLSVessel,
  retrieveMlsVessel,
  getMLSVessel,
  getByType,
  get,
  getVessel,
  create,
  update,
  updateTransactionVessel,
  remove,
  removeAll,
  findByName,
  findByHullNumber,
  findByHullNumberFromYachtBroker,
  findToysByDeal,
  retrieveMlsFilters,
  getSurveyWaiverData
};

export default VesselAndToyService;
