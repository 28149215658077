import http, { setHeaders } from "../http-common";
import Auth from "../utils/auth";

setHeaders(Auth.getInstance().getAuthToken());

const getChecklists = () => {
  return http.get("/document_task");
};

const getTaskByChecklistId = (id, model) => {
  return http.get(`/document_task/${model}/${id}`);
};

const createTask = (data, model) => {
  return http.post(`/document_task/${model}/create`, data);
};

const updateTask = (id, data, model) => {
  return http.put(`/document_task/${model}/${id}`, data);
};

const removeTask = (id, model, userId = null) => {
  if (userId) {
    return http.delete(`/document_task/${model}/${id}/${userId}`);
  }
  return http.delete(`/document_task/${model}/${id}`);
};

const updateTaskOrder = (data, model) => {
  return http.post(`/document_task/${model}/updateOrder`, { data: data });
};

const uploadDoc = (data) => {
  return http.post(`/document_task/transaction/upload`, { data: data });
};

const eSignDoc = (id) => {
  return http.get(`/document_task/e-sign/${id}`);
};

const getIYBADocumentsByChecklistId = (checklist_id) => {
  return http.get(`/document_task/iyba/${checklist_id}/e-documents`);
}

const parseTemplate = (file_name) => {
  return http.get(`/document_task/templates/parse?file_name=${file_name}`);
}

const getTemplate = (id) => {
  return http.get(`/document_task/templates/${id}`);
};

const createTemplate = (data) => {
  return http.post("/document_task/templates/", data);
};

const updateTemplate = (id, data) => {
  return http.put(`/document_task/templates/${id}`, data);
}

const removeTemplate = (id) => {
  return http.delete(`/document_task/templates/${id}`);
};

const findTemplateByName = (name) => {
  return http.post('/document_task/templates/search-by-name', { name: name });
}

const prefillDocument = (id, mode) => {
  return http.get(`/document_task/prefill/${id}?mode=${mode}`);
}

const updateListOrder = (model, data) => {
  return http.post(`/document_task/${model}/updateOrder`, data);
}

const addTaskTag = (apiBody) => {
  return http.post(`/document_task/company-task/addTag`, apiBody);
}

const deleteTaskTag = (apiBody) => {
  return http.post(`/document_task/company-task/deleteTags`, apiBody);
}

const addIYBATag = (apiBody) => {
  return http.post(`/document_task/iyba-task/addTag`, apiBody);
}

const deleteIYBATag = (apiBody) => {
  return http.post(`/document_task/iyba-task/deleteTags`, apiBody);
}

const getCompanyTagList = (companyId) => {
  return http.get(`/document_task/company-task/findAllTags/${companyId}`);
}

const getIYBATagList = () => {
  return http.get(`/document_task/iyba-task/findAllTags`);
}

const deleteCompanyTag = (tagId, companyId) => {
  return http.delete(`/document_task/company-task/${tagId}/${companyId}`);
}

const deleteUploadDocument = (documentId, data) => {
  return http.put(`/document_task/uploadDocument/${documentId}`, data);
}

const DocumentAndTaskService = {
  getChecklists,
  getTaskByChecklistId,
  createTask,
  updateTask,
  removeTask,
  updateTaskOrder,
  uploadDoc,
  eSignDoc,
  getIYBADocumentsByChecklistId,
  createTemplate,
  getTemplate,
  updateTemplate,
  removeTemplate,
  findTemplateByName,
  parseTemplate,
  prefillDocument,
  updateListOrder,
  addTaskTag,
  deleteTaskTag,
  addIYBATag,
  deleteIYBATag,
  getCompanyTagList,
  getIYBATagList,
  deleteCompanyTag,
  deleteUploadDocument
};

export default DocumentAndTaskService;
