import React, { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import MoonLoader from "react-spinners/MoonLoader";
import { Rnd } from "react-rnd"; // Keep Rnd for resizable
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../loading";
import { height, width } from "@mui/system";
import { useSelector } from "react-redux";
import { allDateFields, dropdownFields } from "../../utils/AppConstants";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Controller } from "react-hook-form";
import FormInputWrapper from "../common/FormInputWapper";
import moment from "moment";
import country from "../../constants/Country";
// import transparentPng from ""
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfRender({
  restoreScrollPosition,
  currentScrollPosition,
  handleHiddenScrollContent,
  isHorizontalScrollbarVisible,
  isAddingText,
  setIsAddingText,
  setIsAddingCheckbox,
  isAddingLogo,
  setIsAddingLogo,
  setCheckboxFields,
  isAddingCheckbox,
  pageProps,
  doc,
  renderPdf,
  mergeProps,
  templatePageCount,
  textFields,
  setTextFields,
  logoFields,
  setLogoFields,
  handleLogoClick,
  handleTextChange,
  handleCheckboxSize,
  handleTextDelete,
  handleTextFontSize,
  handleLogoBackground,
  handleLogoDelete,
  checkboxFields,
  handleCheckboxChange,
  handleCheckboxClick,
  handleTextBackground,
  handleCheckboxDelete,
  handleCheckboxBackground,
  signatureFields,
  setSignatureFields,
  signatureAssigner,
  getSignatureBgColor,
  handleSignatureDelete,
  formFieldsProps,
  reloadKey,
  modalPosition,
  setModalPosition,
  handleFieldChange,
  documentName,
  savePDF,
  templateFields,
  allFormFields,
  savePdfField,
  modalLoading,
}) {
  const currentPage = useRef(0);

  // for vessel formFields of pdf
  const { vesselFields, ybaaAmendmentField, acceptanceOfVesselField, sellerClosing, agreementAmmendmuntField, buyerClosing, agreementExclusion, termFields, tradeInVesselFields, offerFields, coBrokerFields, buyerFields, coBuyerFields, sellerFields, coSellerFields, exclusionFields, updatedFormFields, handleFormFieldBackground, handleFormFieldDelete, handleFormFieldFontSize, formFields, setFormFields } = formFieldsProps;

  const { mergedPdfUrl, setMergedPdfUrl, mergeRef } = mergeProps;
  const [numPages, setNumPages] = useState(0);
  const [files, setFiles] = useState(null)
  const { pageNumber, setPageNumber, size, setSize } = pageProps;
  const [isLoading, setIsLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [e, setE] = useState(null);

  //Remove null manufactures from all data manufactures
  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];

  });
  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => mfr);

  const [renderedScale, setRenderedScale] = useState(null);
  const pdfRef = useRef(null);
  const pageRefs = useRef(Array(numPages).fill().map(() => React.createRef()));
  //get number of pages
  useEffect(() => {
    pageRefs.current = Array(numPages).fill().map(() => React.createRef());
  }, [numPages]);
  //scroll to page on page number click
  useEffect(() => {
    if (pageNumber > 0 && pageNumber <= numPages) {
      const index = pageNumber - 1;
      const ref = pageRefs.current[index]?.current;
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Ref for page ${pageNumber} is not ready`);
      }
    }
  }, [pageNumber, numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setIsLoading(false);
    setNumPages(numPages);
  }

  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);

  useEffect(() => {
    if (fileOptions?.url) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [fileOptions]);

  // Adjust text fields when zoom level changes
  useEffect(() => {
    setTextFields(prev => prev.map(textField => ({
      ...textField,
      size: size,

    })));
    setCheckboxFields(
      prev => prev.map(cFields => ({
        ...cFields,
        size: size,
      }))
    )
  }, [size]);

  // function for make dropdown options array dynamically field name wise
  const getDropdownValues = (fieldName) => {
    switch (fieldName) {
      case "vessel_make":
        return dataManuFecturers || [];

      case "buyer_country":
        return country.map((country, ind) => country?.code) || [];

      case "seller_country":
        return country.map((country, ind) => country?.code) || [];

      case "offer_delivery_loc_country":
        return country.map((country, ind) => country?.code) || [];

      default:
        return [];
    }
  }

  const transactions = useSelector((state) => state?.transactions)
  // handle click over pdf
  const handlePdfClick = async (e, pageNumber) => {
    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    e.stopPropagation()
    setModalPosition({ x: 0, y: 0, id: null })
    if (pageElement) {
      const rect = pageElement.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      const xPosition = offsetX / size;  // Store position in unscaled coordinates
      const yPosition = offsetY / size;  // Store position in unscaled coordinates
      // if (["text", "textarea", "checkbox"].includes(e.target.type) && !e.target.getAttribute("data-is-custom")) {

      // store clicked field information from  template
      const clickedTemplateField = templateFields?.filter((templateField) => templateField?.key === e.target.name)?.[0] || {};
      // get value of clicked field
      const clickedTemplateFieldValue = allFormFields[clickedTemplateField?.value] || "";

      if (clickedTemplateField && (Object.keys(clickedTemplateField).length > 0) && (clickedTemplateField?.type === "PDFTextField") && (clickedTemplateField?.is_editable === true)) {
        // if field have multiple fields
        if (clickedTemplateField?.is_multi_field === true && (Object.keys(clickedTemplateField.multi_fields).length > 0)) {
          const multiFieldsNames = [...clickedTemplateField?.multi_fields];
          let multiFieldsWithValues = {}; // for multiple fields name and value
          let dropdownFieldsWithValues = {}; // for dropdown options
          let mainFieldValue = ""; // for main field value showing 
          // make multifields object with values
          for (let i = 0; i < multiFieldsNames?.length; i++) {
            const fieldValue = allFormFields[multiFieldsNames[i]];
            let dropdownValue = await getDropdownValues(multiFieldsNames[i]) || []; // get dropdown options
            multiFieldsWithValues = {
              ...multiFieldsWithValues,
              // [multiFieldsNames[i]]: [fieldValue, allDateFields?.includes(multiFieldsNames[i])], // for handle datev in multi field
              [multiFieldsNames[i]]: fieldValue,
            };
            dropdownFieldsWithValues = {
              ...dropdownFieldsWithValues,
              [multiFieldsNames[i]]: dropdownValue,
            }
            mainFieldValue = `${mainFieldValue}${mainFieldValue === "" ? mainFieldValue : " "}${fieldValue || ""}`; // do together all field values
          }
          // selt object of multifields
          setModalPosition({
            x: xPosition,
            y: yPosition,
            id: Date.now(),
            size: size,
            pageNumber: pageNumber,
            // value: e.target.type === "checkbox" ? e.target.checked === "on" ? false : true : e.target.value,
            value: mainFieldValue || e.target.value || "",
            type: e.target.type,
            label: clickedTemplateField?.value,
            name: e.target.name,
            is_multi_field: true,
            multi_fields: multiFieldsWithValues,
            dropdown_fields: dropdownFieldsWithValues,
          });
        } else {
          let dropdownValues = await getDropdownValues(clickedTemplateField?.value) || []; // get dropdown options
          setModalPosition({
            x: xPosition,
            y: yPosition,
            id: Date.now(),
            size: size,
            pageNumber: pageNumber,
            // value: e.target.type === "checkbox" ? e.target.checked === "on" ? false : true : e.target.value,
            value: clickedTemplateFieldValue,
            type: e.target.type,
            // label: transactions?.transaction?.t_edocuments?.filter((e) => e?.title === documentName)?.[0]?.t_esign_template?.template?.template_fields?.filter?.((field) => field?.key === e.target.name)?.[0]?.value,
            label: clickedTemplateField?.value,
            is_date_field: allDateFields?.includes(clickedTemplateField?.value),
            is_multi_field: false,
            name: e.target.name,
            dropdown_fields: dropdownValues,
          })
        }
      } else if (isAddingText) {
        setTextFields([
          ...textFields,
          {
            x: xPosition,
            y: yPosition,
            text: '',
            id: Date.now(),
            fontSize: 15,
            background: false,
            size: size,
            pageNumber: pageNumber,
            initialWidth: 50,  // Store initial unscaled width
            initialHeight: 28,   // Store initial unscaled height
            showControls: true  // Show controls when text field is added
          }
        ]);
        setIsAddingText(false);
      } else if (isAddingCheckbox) {
        setCheckboxFields([
          ...checkboxFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            checked: 0,
            id: Date.now(),
            background: true,
            size: size,
            initialWidth: 25,  // Store initial unscaled width
            initialHeight: 25,
          }
        ]);
        setIsAddingCheckbox(false);
      } else if (isAddingLogo) {
        setLogoFields([
          ...logoFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            logo: "/images/logo-upload.svg",
            id: Date.now(),
            // background: true,
            size: size,
            file: {},
            initialWidth: logoFields?.[logoFields?.length - 1]?.initialWidth ?? 54,  // Store initial unscaled width
            initialHeight: logoFields?.[logoFields?.length - 1]?.initialHeight ?? 54,
          }
        ]);
        setIsAddingLogo(false);
      }
    }
  };

  // when form data updates , form fields will update
  useEffect(() => {
    if (formFields) {
      if (updatedFormFields?.length === 1) {
        setFormFields((prev) => {
          return prev?.map((field) => {
            let updatedField = updatedFormFields?.find((uField) => uField?.fieldId === field?.id) || {};
            if (Object.keys(updatedField).length > 0) {
              return { ...field, text: updatedField?.fieldValue }
            }
            return field;
          })
        });
      } else if (updatedFormFields?.length > 1) {
        // update multiple fields when changes happens in offer form from editor
        setFormFields((prev) => {
          return prev?.map((field) => {
            // check updated field by name 
            for (let i = 0; i < updatedFormFields?.length; i++) {
              if (field.name === updatedFormFields[i]?.fieldName) {
                return { ...field, text: updatedFormFields[i]?.fieldValue }
              }
            }
            return field;
          })
        });
      }
    }
  }, [updatedFormFields])

  const handleFileChange = async (e) => {
    // manage selected file in one state for merging purpose
    setFiles((prev) => [e.target.files[0]]);
  }

  // handle drop signature/initials/date over pdf
  const handleDrop = (e, pageNumber, type) => {
    e.preventDefault();
    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    const rect = pageElement.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const xPosition = offsetX / size;  // Store position in unscaled coordinates
    const yPosition = offsetY / size;  // Store position in unscaled coordinates

    // handle form fields dropping
    if (pageElement && type === "FormFields") {
      setFormFields((prev) => {
        const possibleFields = {
          vesselFields: vesselFields,
          termFields: termFields,
          buyerFields: buyerFields,
          coBuyerFields: coBuyerFields,
          sellerFields: sellerFields,
          coSellerFields: coSellerFields,
          tradeInVesselFields: tradeInVesselFields,
          offerFields: offerFields,
          exclusionFields: exclusionFields,
          coBrokerDealSideFields: coBrokerFields[0],
          coBrokerOtherSideFields: coBrokerFields[1],
          ybaaAmendmentField: ybaaAmendmentField,
          acceptanceOfVesselField: acceptanceOfVesselField,
          agreementExclusion: agreementExclusion,
          agreementAmmendmuntField: agreementAmmendmuntField,
          buyerClosing: buyerClosing,
          sellerClosing: sellerClosing,
        };
        const formType = e.dataTransfer.getData('formType');
        const initialField = possibleFields[formType]?.filter((vField) => vField.fieldId === e.dataTransfer.getData('fieldId'))[0] || {};
        if (initialField && Object.keys(initialField).length > 0) {
          prev = (prev && prev?.length > 0) ? prev : [];
          return [
            ...prev,
            {
              name: initialField?.fieldName,
              x: xPosition,
              y: yPosition,
              formName: formType,
              text: String(initialField?.fieldValue),
              id: `${initialField?.fieldId}`,
              uniqueId: String(Date.now()),
              fontSize: 15,
              background: false,
              size: size,
              pageNumber: pageNumber,
              initialWidth: (initialField?.fieldValue?.split('').length) * (15) * size * 0.6,  // Store initial unscaled width
              initialHeight: 28,   // Store initial unscaled height
              showControls: true  // Show controls when text field is added
            }
          ];
        }
      }
      );
    }
    else {
      // handle signature, initials and date dropping
      if (pageElement && ["Signature", "Initials", "Date Signed"].includes(type)) {
        const fieldBgColor = getSignatureBgColor(signatureAssigner);
        let newItem = {
          coord: {
            x: xPosition,
            y: yPosition,
            width: 140,
            height: 26
          },
          page_number: pageNumber,
          assigner: signatureAssigner || '',
          bg_color: fieldBgColor,
          is_manually_added: true
        };
        switch (type) {
          case 'Signature':
            newItem = {
              key: `Signature_${Date.now()}`,
              value: `Signature_${Date.now()}`,
              type: 'PDFSignature',
              ...newItem
            };
            break;
          case 'Initials':
            newItem = {
              ...newItem,
              key: `Initials_${Date.now()}`,
              value: '',
              type: 'PDFInitial',
              coord: {
                ...newItem.coord,
                width: 80,
                height: 26
              },
            };
            break;
          case 'Date Signed':
            newItem = {
              key: `DateSignature_${Date.now()}`,
              value: '',
              type: 'PDFDateSigned',
              ...newItem
            };
            break;
          default:
            break;
        }
        setSignatureFields((prev) => [...prev, { ...newItem }]);
      }
    }
  }

  const loading = renderedPageNumber !== pageNumber;
  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }
  let checkboxImagesUrl = ["/images/check-box-tick.png", "/images/check-box-cross.png"];

  // function for handle change of multi field and reflect in main field
  const handleMultiFieldChange = (e, key) => {
    setModalPosition((prev) => {
      let mainFieldValue = "";
      Object.keys(prev?.multi_fields).map((fieldKey, index) => {
        const addValue = fieldKey === key ? e.target.value : prev?.multi_fields[fieldKey]; // for add value of all fields one bye one
        mainFieldValue = `${mainFieldValue}${mainFieldValue === "" ? mainFieldValue : " "}${addValue || ""}`;
      });
      return { ...prev, value: mainFieldValue, multi_fields: { ...prev.multi_fields, [key]: e.target.value } }
    })
  }

  return (
    fileOptions?.url ? <div className="bg-gradient">
      <div className="d-flex justify-content-center">
        <Document
          key={reloadKey}
          file={fileOptions}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            setIsLoading(false);
          }}
          className={`d-flex align-items-center pdf-document-viewer ${isHorizontalScrollbarVisible ? 'pdf-horizontal-scroll' : ''} ${loading ? 'pdf-loaded' : ''}`}
          onRender={() => <Loading loading={true} />}
          loading={() => <div style={{ height: '75vh', display: 'flex', alignItems: 'center' }}><MoonLoader color="#0063A6" /></div>}
        >
          <div ref={pdfRef}>
            {Array.from(new Array(numPages)).map((_, index) => (
              <div key={index} className="mb-2 shadow" ref={pageRefs.current[index]}>
                <Page
                  onClick={(e) => handlePdfClick(e, index + 1)}
                  onDragOver={(e) => { e.preventDefault(); }}
                  onDrop={(e) => { handleDrop(e, index + 1, e.dataTransfer.getData('type')) }}
                  key={`${index}@${90}_canvas@${size}_canvas`}
                  scale={size}
                  wrap={true}
                  renderMode="canvas"
                  onRenderSuccess={() => {
                    handleHiddenScrollContent();
                    restoreScrollPosition(currentScrollPosition);
                  }}
                  pageNumber={index + 1}
                  enhanceTextSelection={true}
                  onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
                  renderAnnotationLayer={true}
                  renderForms={true}
                  onRenderAnnotationLayerSuccess={(e) => { renderPdf(e, index + 1); setRenderedPageNumber(index + 1); }}
                  loading={() => <Loading loading={true} />}
                  className={`position-relative react-pdf__Page custom-editor-page ${(mergedPdfUrl || (templatePageCount && numPages > templatePageCount)) && "merge-pdf__page"}`}
                >
                  {
                    modalPosition.id && modalPosition.pageNumber === index + 1 && (
                      <>
                        <Rnd
                          // bounds="parent"
                          key={modalPosition.id}
                          position={{ x: modalPosition.x * size, y: modalPosition.y * size }} // Scale position by zoom factor
                          minHeight={10 * size}
                          minWidth={15 * size}
                          // dragHandleClassName="handle-modal-drag"
                          enableResizing={false}
                          onDragStop={(e, data) => {
                            // prevent position change on change of select dropdowns 
                            if (e.target.tagName !== "SELECT") {
                              // Update x/y based on zoom level
                              setModalPosition(prevState => {
                                if (prevState.id === modalPosition.id) {
                                  return {
                                    ...prevState,
                                    x: data.x / size,  // Store in unscaled coordinates
                                    y: data.y / size,  // Store in unscaled coordinates
                                  };
                                } return prevState
                              });
                            }
                          }}
                          style={{
                            zIndex: 9999,
                          }}
                        >
                          <Box
                            sx={{
                              // width: "auto",
                              bgcolor: "white",
                              border: "1px solid #ddd",
                              borderRadius: "8px",
                              boxShadow: 24,
                              p: 3,
                              zIndex: 99999, // Ensure it appears above other elements
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {/* Text Field */}
                            {
                              (modalPosition?.type === "text" || modalPosition?.type === "textarea") && (
                                modalPosition?.hasOwnProperty('multi_fields') ? (
                                  <div class="row w-100">
                                    {/* implementation for multiple fields on one popup */}
                                    <div class="col" style={{ borderRight: "5px solid #3e8eff" }}>
                                      <h4 className="mb-4" style={{ color: "#3e8eff" }}>Save to Database</h4>
                                      {Object.keys(modalPosition?.multi_fields).map((key, index) => {
                                        if (dropdownFields?.includes(key)) {
                                          // for dropdown fields
                                          return (<FormInputWrapper label={key} style={{ paddingRight: "0px" }}>
                                            <select
                                              className="uk-select mb-2"
                                              style={{ fontSize: "14px" }}
                                              value={modalPosition?.multi_fields[key] || ""} // Bind the value of the select to the state
                                              onChange={(e) => { handleMultiFieldChange(e, key); } // Update the state when the user selects an option
                                              }
                                            >
                                              {/* <option key={`dropdown_option_default`} value={modalPosition?.multi_fields[key]}>{modalPosition?.multi_fields[key]}</option> */}
                                              {(modalPosition?.dropdown_fields[key])?.map((dropdownOptionsKey, idx) => {
                                                return <option key={`dropdown_options_${idx}`} value={dropdownOptionsKey}>{dropdownOptionsKey}</option>
                                              })}
                                            </select>
                                          </FormInputWrapper>)
                                        } else {
                                          return (
                                            <>
                                              <TextField
                                                key={key}
                                                type={modalPosition?.type}
                                                label={key} // Set label dynamically
                                                multiline={modalPosition?.type === "text" ? false : true}
                                                rows={modalPosition?.type === "text" ? 1 : 4}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                name={key} // Use the key as the name of the field
                                                sx={{ mb: index === Object.keys(modalPosition?.multi_fields).length - 2 ? 2 : 3 }}
                                                // inputRef={(input) => input && input.focus()}
                                                value={modalPosition?.multi_fields[key] || ""}
                                                onChange={(e) => {
                                                  // setE(e);
                                                  // handleFieldChange(e);
                                                  handleMultiFieldChange(e, key)
                                                }} // Update the state when the user selects an option
                                              />
                                            </>
                                          );
                                        }
                                      })}
                                    </div>
                                    <div class="col pr-0 d-flex flex-column justify-content-between" >
                                      <div>
                                        <h4 className="mb-4" style={{ color: "#3e8eff" }}>Show in Field</h4>
                                        <div className="d-flex flex-column justify-content-between">
                                          <TextField
                                            key={modalPosition?.label}
                                            type={modalPosition?.type}
                                            label={modalPosition?.label} // Set label dynamically
                                            multiline={modalPosition?.type === "text" ? false : true}
                                            rows={modalPosition?.type === "text" ? 1 : 4}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            name={modalPosition?.label} // Use the key as the name of the field
                                            sx={{ mb: index === Object.keys(modalPosition?.multi_fields).length - 2 ? 2 : 3 }}
                                            // inputRef={(input) => input && input.focus()}
                                            value={modalPosition?.value || ""}
                                            onChange={(e) => {
                                              // setE(e);
                                              // handleFieldChange(e);
                                              setModalPosition({ ...modalPosition, value: e.target.value })
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* Buttons */}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          // marginTop : "100%",
                                          gap: 1,
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="error"
                                          sx={{ width: "45%" }}
                                          onClick={() => setModalPosition({ x: null, y: null, id: null })}
                                        >
                                          Close
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            savePdfField(modalPosition?.is_multi_field ? modalPosition?.multi_fields : { [modalPosition?.label]: modalPosition?.value });
                                            // renderPdf(e, pageNumber);
                                            // setModalPosition({ x: null, y: null, id: null });
                                            // setE(null);
                                          }}
                                          sx={{ width: "45%" }}
                                          disabled={modalLoading} // Disable the button while loading
                                        >
                                          {modalLoading ? (
                                            <CircularProgress size={24} color="inherit" />
                                          ) : (
                                            'Save'  // Show 'Save' text when not loading
                                          )}
                                        </Button>
                                      </Box>
                                    </div>
                                  </div>
                                  // </div>
                                ) : (
                                  <>
                                    {(!modalPosition?.is_date_field) ?
                                      (
                                        <>
                                          {dropdownFields?.includes(modalPosition?.label) ?
                                            <FormInputWrapper label={modalPosition?.label} style={{ paddingRight: "0px" }}>
                                              <select
                                                className="uk-select mb-2"
                                                style={{ fontSize: "14px" }}
                                                value={modalPosition?.value || ""} // Bind the value of the select to the state
                                                onChange={(e) => {
                                                  setModalPosition({ ...modalPosition, value: e.target.value })
                                                } // Update the state when the user selects an option
                                                }
                                              >
                                                {/* <option key={`dropdown_option_default`} value={modalPosition?.value}>{modalPosition?.value}</option> */}
                                                {(modalPosition?.dropdown_fields)?.map((dropdownOptionsKey, idx) => {
                                                  return <option key={`dropdown_options_${idx}`} value={dropdownOptionsKey}>{dropdownOptionsKey}</option>
                                                })}
                                              </select>
                                            </FormInputWrapper>
                                            :
                                            <TextField
                                              type={modalPosition?.type}
                                              label={modalPosition?.label}
                                              multiline={modalPosition?.type === "text" ? false : true}
                                              rows={modalPosition?.type === "text" ? 1 : 4}
                                              variant="outlined"
                                              fullWidth
                                              size="small"
                                              name={modalPosition?.name}
                                              sx={{ mb: 3 }}
                                              inputRef={(input) => input && input.focus()}
                                              value={modalPosition?.value || ""}
                                              onChange={(e) => {
                                                setE(e);
                                                handleFieldChange(e)
                                                setModalPosition({ ...modalPosition, value: e.target.value })
                                              }}
                                            />
                                          }
                                        </>
                                      ) :
                                      (
                                        <>
                                          <FormInputWrapper label={modalPosition?.label} style={{ paddingRight: "0px" }}>
                                            <DatePicker
                                              className="uk-input mt-1 d-flex justify"
                                              label={modalPosition?.label}
                                              style={{ cursor: "pointer", color: "#000" }}
                                              format={'MM-DD-YYYY'}
                                              value={modalPosition?.value && dayjs(modalPosition?.value)}
                                              onChange={(date, dateString) => {
                                                // setE(e);
                                                // handleFieldChange(e)
                                                setModalPosition({ ...modalPosition, value: dateString ? moment(dateString)?.format("YYYY-MM-DD") : null });
                                                // checkOfferDate();
                                              }}
                                            />
                                          </FormInputWrapper>
                                        </>
                                      )}
                                    {/* Buttons */}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 1,
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: "45%" }}
                                        onClick={() => setModalPosition({ x: null, y: null, id: null })}
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          savePdfField(modalPosition?.is_multi_field ? modalPosition?.multi_fields : { [modalPosition?.label]: modalPosition?.value });
                                          // renderPdf(e, pageNumber);
                                          // setModalPosition({ x: null, y: null, id: null });
                                          // setE(null);
                                        }}
                                        sx={{ width: "45%" }}
                                        disabled={modalLoading} // Disable the button while loading
                                      >
                                        {modalLoading ? (
                                          <CircularProgress size={24} color="inherit" />
                                        ) : (
                                          'Save'  // Show 'Save' text when not loading
                                        )}
                                      </Button>
                                    </Box>
                                  </>
                                )
                              )
                            }
                            {modalPosition?.type === "checkbox" && (
                              <Box sx={{ mb: 3 }}>
                                <FormControlLabel
                                  label={modalPosition?.label}
                                  name={modalPosition?.name}
                                  control={<Checkbox checked={modalPosition?.value}
                                    onChange={(e) => {
                                      setE(e);
                                      // handleFieldChange(e)
                                      setModalPosition({ ...modalPosition, value: e.target.checked });
                                    }} />}
                                />
                              </Box>
                            )}
                          </Box>
                        </Rnd>

                      </>
                    )
                  }

                  {/* Render Text Fields */}
                  {textFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.initialWidth * size,  // Scale width by zoom factor
                          height: field.initialHeight * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-textbox-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                initialWidth: ref.offsetWidth / size,  // Store unscaled width
                                initialHeight: ref.offsetHeight / size,  // Store unscaled height
                                width: ref.offsetWidth,  // Store scaled width
                                height: ref.offsetHeight  // Store scaled height
                              };
                            }
                            return tField;
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return tField;
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "2px solid skyblue",
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="border border-0"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <textarea
                            value={field.text}
                            onChange={(e) => {
                              const textArea = e.target
                              let newWidth = Math.min(((textArea.scrollWidth) * (field?.size)), ((Math.max(...field?.text.split('\n').map(line => line.length))) * (field?.fontSize) * (field?.size)))
                              // let newWidth = Math.min((textArea.scrollWidth),(textArea.value.split('\n').map(line => line.length) * (minWidth) * size * (field?.fontSize/15)));
                              let minWidth = 50
                              let minHeight = 28
                              let newHeight = Math.min(((textArea.scrollHeight) * (field?.size)), (((field?.text.split('\n').length) * ((field?.fontSize) * (1.5))) * (field?.size) + (20)))
                              // let newHeight = Math.min((textArea.scrollHeight), ((textArea.value.split('\n').length + 1) * (minHeight) * size * (field?.fontSize/25)));
                              setTextFields(prev => prev.map((tField) =>
                                tField.id === field.id ? {
                                  ...tField,
                                  text: textArea.value,
                                  initialWidth: Math.max(newWidth, minWidth) / size,
                                  initialHeight: Math.max(minHeight, newHeight) / size
                                } : tField
                              )
                              );
                              // handleTextChange(e, field.id)
                            }}
                            style={{
                              height: '100%',
                              width: '100%',
                              backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                              border: '2px solid black',
                              fontSize: `${(field.fontSize) * size}px`,
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              outline: "none",
                              overflow: "hidden"
                            }}
                            className="p-1 border border-0 outline-none"
                            data-is-custom="true"
                          />
                          <div className="d-flex justify-content-center align-items-center m-1 gap-2">
                            <div className="handle-textbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${25 * size}px`, cursor: "pointer" }}
                              onClick={(e) => handleTextDelete(e, field.id)}
                              draggable={false}
                            ></i>
                            <input type="number" min={6} max={50}
                              onChange={(e) => handleTextFontSize(e, field.id)}
                              value={field.fontSize}
                              className="rounded size-changer mx-1 p-0"
                              style={{
                                height: `${20 * size}px`,
                                width: `${40 * size}px`,
                                border: '2px solid black',
                                fontSize: `${15 * size}px`,
                                textAlign: 'center'
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="position-absolute m-1"
                          onClick={(e) => handleTextBackground(field?.background, field.id)}
                          style={{
                            backgroundColor: "#D9E5FF",
                            border: "1px solid black",
                            cursor: "pointer",
                            width: `${20 * size}px`,
                            height: `${20 * size}px`,
                            left: "100%"
                          }}>
                          {
                            !field?.background && <img src="/images/transparent.png" draggable={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                              }} alt="transparent" />
                          }
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Checkbox Fields */}
                  {checkboxFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={true} // Lock aspect ratio to make resizing square
                        dragHandleClassName="handle-checkbox-drag"
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              return {
                                ...cField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return cField;
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newSize = ref.offsetWidth; // The width and height will be the same
                              return {
                                ...cField,
                                initialWidth: newSize / size,  // Store unscaled width
                                initialHeight: newSize / size,  // Store unscaled height
                                width: newSize,  // Store scaled width
                                height: newSize,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 3,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        <div
                          className="p-0 m-0 position-relative"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                        >
                          {/* checkbox image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: `${field.background ? "white" : "transparent"}`,
                              cursor: "pointer",
                              userSelect: "none"
                            }}
                            onClick={(e) => { handleCheckboxClick(field.id) }}
                          >
                            {
                              field?.checked !== 2 && <img className="m-0 p-0" draggable={false}
                                src={checkboxImagesUrl[field?.checked]} style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain"
                                }} alt="checkbox" />
                            }
                          </div>
                          {/* checkbox controls */}
                          <div className="position-absolute d-flex  align-items-center justify-content-between  p-0" style={{ top: "100%", width: `${50}px`, marginTop: `${5 * size}px` }}>
                            {/* checkbox drag button */}
                            <div className="handle-checkbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${20 * size}px`, cursor: "pointer", }}
                              onClick={(e) => handleCheckboxDelete(e, field.id)}
                              draggable={false}

                            ></i>

                          </div>
                          {/* checkbox transparency button */}
                          <div
                            className="position-absolute m-1"
                            onClick={(e) => handleCheckboxBackground(field?.background, field.id)}
                            style={{
                              backgroundColor: "#D9E5FF",
                              border: "1px solid black",
                              cursor: "pointer",
                              width: `${15 * size}px`,
                              height: `${15 * size}px`,
                              left: "100%",
                              userSelect: "none"
                            }}>
                            {
                              field?.background && <img src="/images/transparent.png" draggable={false}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover"
                                }} alt="transparent" />
                            }
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Logo */}
                  {logoFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={true} // allow any size not only square
                        dragHandleClassName="handle-logo-drag"
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setLogoFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              return {
                                ...cField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return cField;
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setLogoFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newWidth = ref.offsetWidth; // Width as per resizing
                              const newHeight = ref.offsetHeight; // Height as per resizing
                              return {
                                ...cField,
                                initialWidth: newWidth / size,  // Store unscaled width
                                initialHeight: newHeight / size,  // Store unscaled height
                                width: newWidth,  // Store scaled width
                                height: newHeight,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 1000,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        <div
                          className="p-0 m-0 position-relative"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                        >
                          {/* logo image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: "whitesmoke",
                              cursor: "pointer",
                              userSelect: "none",
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: field.initialHeight,
                              width: field.initialWidth
                            }}
                          // onClick={(e) => { handleLogoClick(field.id) }}
                          >
                            <input type="file"
                              className="m-0 p-0"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e) => {
                                handleLogoClick(field.id, e.target.files[0]);
                              }}
                              alt="logo"
                            />
                            <img src={field.logo} style={{
                              width: field.logo === "/images/logo-upload.svg" ? "50%" : "100%",
                              height: field.logo === "/images/logo-upload.svg" ? "50%" : "100%",
                            }} />
                          </div>
                          {/* Logo controls */}
                          < div className="position-absolute d-flex  align-items-center justify-content-between  p-0" style={{ top: "100%", width: `${50}px`, marginTop: `${5 * size}px` }}>
                            {/* Logo drag button */}
                            <div className="handle-logo-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${20 * size}px`, cursor: "pointer", }}
                              onClick={(e) => handleLogoDelete(e, field.id)}
                              draggable={false}

                            ></i>

                          </div>
                          {/* Logo transparency button */}
                          < div className="position-absolute d-flex m-1 align-items-center justify-content-between  p-0" style={{ left: "100%", border: "1px solid #3e8eff", marginTop: `${5 * size}px` }}>
                            <i className=" fa fa-copy mx-1 m-2"
                              aria-hidden="true"
                              style={{ fontSize: `${10 * size}px`, cursor: "pointer", color: "#3e8eff" }}
                              onClick={(e) => {
                                const pageElement = pageRefs.current[pageNumber - 1]?.current;
                                if (pageElement) {
                                  const rect = pageElement.getBoundingClientRect();
                                  const offsetX = e.clientX - rect.left;
                                  const offsetY = e.clientY - rect.top;
                                  const xPosition = offsetX / size;  // Store position in unscaled coordinates
                                  const yPosition = offsetY / size;
                                  setLogoFields([
                                    ...logoFields,
                                    {
                                      x: xPosition,
                                      y: yPosition,
                                      pageNumber: pageNumber,
                                      logo: field.logo,
                                      file: field.file ?? {},
                                      id: Date.now(),
                                      size: size,
                                      initialWidth: field.initialWidth,  // Store initial unscaled width
                                      initialHeight: field.initialHeight,
                                    }
                                  ]);
                                }
                              }}
                              draggable={false}
                            ></i>
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* render signature fields  */}
                  {signatureFields?.map((field) => (
                    field.page_number === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.key}
                        position={{ x: field.coord.x * size, y: field.coord.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.coord.width * size,  // Scale width by zoom factor
                          height: field.coord.height * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, width: ref.offsetWidth / size, height: ref.offsetHeight / size }
                              };
                            }
                            return sField;
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, x: data.x / size, y: data.y / size }
                              };
                            }
                            return sField;
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "1px solid black",
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="border border-0"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <input
                            style={{
                              fontSize: "15px",
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              cursor: 'move',
                              width: '100%',
                              height: '100%',
                              padding: '5px',
                              outline: 'none',
                              borderRadius: '2px',
                              backgroundColor: field.bg_color,
                              border: '1px solid black'
                            }}
                            id={field.key}
                            onClick={(event) => event.stopPropagation()}
                            className="p-1 border border-0 outline-none"
                            value={field.type === "PDFSignature" ? "Signature" : (field.type === "PDFInitial" ? "XX" : "Date")}
                            readOnly
                          />
                        </div>
                        <div
                          className="position-absolute p-1"
                          style={{
                            left: "100%"
                          }}>
                          <i
                            className="fa fa-trash fa-lg text-danger"
                            aria-hidden="true"
                            style={{ fontSize: `${25 * size}px`, cursor: "pointer" }}
                            onClick={(e) => handleSignatureDelete(e, field.key)}
                            draggable={false}
                          ></i>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render form Fields */}
                  {formFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.initialWidth * size,  // Scale width by zoom factor
                          height: field.initialHeight * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-textbox-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setFormFields(prevState => prevState.map(formField => {
                            if (formField.uniqueId === field.uniqueId) {
                              return {
                                ...formField,
                                initialWidth: ref.offsetWidth / size,  // Store unscaled width
                                initialHeight: ref.offsetHeight / size,  // Store unscaled height
                                width: ref.offsetWidth,  // Store scaled width
                                height: ref.offsetHeight  // Store scaled height
                              };
                            }
                            return formField;
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setFormFields(prevState => prevState.map(formField => {
                            if (formField.uniqueId === field.uniqueId) {
                              return {
                                ...formField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return formField;
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "2px solid skyblue",
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="border border-0"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <textarea
                            value={field.text}
                            onChange={(e) => {
                              const inputBox = e.target
                              let newWidth = Math.min(((inputBox.scrollWidth) * (field?.size)), ((Math.max(...field?.text.split('\n').map(line => line.length))) * (field?.fontSize) * (field?.size)))
                              // let newWidth = Math.min((textArea.scrollWidth),(textArea.value.split('\n').map(line => line.length) * (minWidth) * size * (field?.fontSize/15)));
                              let minWidth = 50
                              let minHeight = 28
                              let newHeight = Math.min(((inputBox.scrollHeight) * (field?.size)), (((field?.text.split('\n').length) * ((field?.fontSize) * (1.5))) * (field?.size) + (20)))
                              // let newHeight = Math.min((textArea.scrollHeight), ((textArea.value.split('\n').length + 1) * (minHeight) * size * (field?.fontSize/25)));
                              setFormFields(prev => prev.map((formField) =>
                                formField.uniqueId === field.uniqueId ? {
                                  ...formField,
                                  text: inputBox.value,
                                  initialWidth: Math.max(newWidth, minWidth) / size,
                                  initialHeight: Math.max(minHeight, newHeight) / size
                                } : formField
                              )
                              );
                              // handleTextChange(e, field.id)
                            }}
                            style={{
                              height: '100%',
                              width: '100%',
                              backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                              border: '2px solid black',
                              fontSize: `${(field.fontSize) * size}px`,
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              outline: "none",
                              overflow: "hidden"
                            }}
                            className="p-1 border border-0 outline-none"
                          />

                          <div className="d-flex justify-content-center align-items-center m-1 gap-2">
                            <div className="handle-textbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${25 * size}px`, cursor: "pointer" }}
                              onClick={(e) => handleFormFieldDelete(e, field.uniqueId)}
                              draggable={false}
                            ></i>
                            <input type="number" min={6} max={50}
                              onChange={(e) => handleFormFieldFontSize(e, field.uniqueId)}
                              value={field.fontSize}
                              className="rounded size-changer mx-1 p-0"
                              style={{
                                height: `${20 * size}px`,
                                width: `${40 * size}px`,
                                border: '2px solid black',
                                fontSize: `${15 * size}px`,
                                textAlign: 'center'
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="position-absolute m-1"
                          onClick={(e) => handleFormFieldBackground(field?.background, field.uniqueId)}
                          style={{
                            backgroundColor: "#D9E5FF",
                            border: "1px solid black",
                            cursor: "pointer",
                            width: `${20 * size}px`,
                            height: `${20 * size}px`,
                            left: "100%"
                          }}>
                          {
                            !field?.background && <img src="/images/transparent.png" draggable={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                              }} alt="transparent" />
                          }
                        </div>
                      </Rnd>
                    )
                  ))}
                </Page>
              </div>
            ))}
          </div>
        </Document>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>

      :
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
        className="bg-gradient"
      >
        {/* <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={mergeRef} hidden />
      <button
        style={{
          backgroundColor: '#3e8eff',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '4px',
          fontSize: '16px',
          cursor: 'pointer',
        }}
      onClick={handleSelectFile}
      >
        Select PDF file
      </button>
      <p
        style={{
          marginTop: '10px',
          color: '#333',
          fontSize: '14px',
        }}
      >
        Or drop your PDF file here
      </p> */}
      </div>

  )
}

export default PdfRender;
