'use strict';

import moment from "moment";
import { phoneNumber } from "./phonenumber";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const createFileFromLink = async (fileUrl, fileName) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: 'application/pdf' });
    } catch (error) {
        console.log('Error:', error);
        return null;
    }
}

export const getHandSignedDocumentPath = (fileName) => {
    return fileName ? `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}hand-signed-documents/${fileName}` : '';
}

export const getEsignedDocumentpath = (fileName) => {
    return fileName ? `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}e-signed-documents/${fileName}` : '';
}

export const momentDateWithTimezone = (date) => {
    let formatedDate = '';
    if (date) {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        formatedDate = `${moment(date).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
    }
    return formatedDate;
}

// defined functions for get required and formatted data from api response , for users and participant both for users Module
//get data functions for users
 export const getUserFullName = (data) => {
    let userName = `${data?.first_name || ""} ${data?.last_name || ""}`;
    userName = userName === " " ? "-" : userName;
    return userName;
  };

  export const getUserRole = (data) => {
    const userInRole = data?.UserInRole || {};
    return userInRole?.UserRole?.name || "-";
  };

  export const getUserBroker = (data) => {
    return data?.is_broker ? "Yes" : "No";
  };

  export const getUserEbSigns = (data) => {
    return data.office_employing_broker_signs ? "Yes" : "No";
  };

  export const getUserEmail = (data) => {
    return data?.email || "-";
  };

  export const getUserMobilePhone = (data) => {
    return phoneNumber(data?.mobile_phone) || "N/A";
  };

  export const getUserCompany = (data) => {
    const company = data?.company || {};
    return company.company_name || "-";
  };

  export const getUserOffice = (data) => {
    const office = data?.office || {};
    return office.office_name || "-";;
  };

  export const getUserAccess = (data) => {
    return data?.access_type || "N/A";
  };

  export const getUserMlsUser = (data) => {
    return data?.mls_user_id || "N/A";
  };

  export const getUserMlsOffice = (data) => {
    return data?.mls_office_id || "N/A";
  };
export const getUserLA = (data) => {
  return data?.listing_count || "N/A";
}
export const getUserDeals = (data) => {
  return data?.deal_count || "N/A";
}
  //for get date in "July 25, 2024" format
  export const getUserCreatedDate = (data) => {
    const date = new Date(data?.createdAt);
    return (
      date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }) || "N/A"
    );
  };

  //get data functions for participants
  export const getParticipantFullName = (data) => {
    let participantName = `${data?.first_name || ""} ${
      data?.last_name || ""
    }`;
    participantName = participantName === " " ? "-" : participantName;
    return participantName;
  };

  export const getParticipantEmail = (data) => {
    return data?.email || "-";
  };

  export const getParticipantMobilePhone = (data) => {
    return phoneNumber(data?.mobile_phone) || "-";
  };

  export const getParticipantStatus = (data) => {
    return data?.active === true ? "Active" : "InActive";
  };

//get data functions for participants
export const getGuestAgentFullName = (data) => {
  let guestAgentName = `${data?.first_name || ""} ${data?.last_name || ""
    }`;
  guestAgentName = guestAgentName === " " ? "-" : guestAgentName;
  return guestAgentName;
};

export const getGuestAgentEmail = (data) => {
  return data?.email || "-";
};

export const getGuestAgentMobilePhone = (data) => {
  return phoneNumber(data?.mobile_phone) || "-";
};

export const getGuestAgentStatus = (data) => {
  return data?.active === true ? "Active" : "InActive";
};

  //this function will get all associated companies for all users and participants 
  // we have to store that data , we will use it for make sheet
  // remains till api gets ready
  export const getParticipantAssociatedCompanies = async (data) => {
    
      // here call api and set needed data
    
  };

  //function for resolve comma problem in CSV for data, which contains comma
  export const commaSolver = (helperArrayForExportData, getDataFunction, data, isExcel) => {
    // added comma contain data in double quotes as solution
    if (!isExcel) {
     return ([
        ...helperArrayForExportData,
        `"${getDataFunction(data)}"`,
      ])
    }
    else {
      return ([
        ...helperArrayForExportData,
        getDataFunction(data),
      ])
    }
  }

  // function for make excel sheet
  export const makeExcelSheet = (exportData, sheetName) => {
    // make worksheet from exportData
    const ws = XLSX.utils.aoa_to_sheet(exportData);
    // make new workbook object
    const wb = XLSX.utils.book_new();
    // append worksheet in workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // `write` tries to write and save an XLSB file
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // excelBuffer binary data send to saveAsExcel function
    saveAsExcel(excelBuffer, sheetName);
  };

  // function for make csv file
  export const makeCsvFile = (exportData, fileName) => {
    // converted exportData into csvString for make csv file
    const csvString = exportData.map((row) => row.join(",")).join("\n"); 
    // passed csv string in blob for convert csvString in csv file format
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    // used saveAs for download csv file
    saveAs(blob, `${fileName}.csv`);
  };

  // function for save and download excel file
  export const saveAsExcel = (buffer, fileName) => {
    // blob will get buffer data and saveAs function will save excel file to system
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };
// function for change date format in yyyy-MM-DD format (used for api payload formatted date)
export const dateFormatter = (date) => {
    // if there is no date means if null comes then it will be back
    if (date) {
        return moment(date).local().format("yyyy-MM-DD");
    }
    return date;
}
