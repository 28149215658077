import Breadcrumb from './BreadCrumb';

const FormPage = ({ links, title, vesselName = '', uniqueId = '', children, mls_vessel_id = null }) => {
  return (
    <div className="uk-container uk-container-small uk-position-relative">
      <Breadcrumb links={links} />
      <div className="content add-forms">
        <span className='custom-h2'>{title}  <span style={{ fontWeight: 500 }}>
          {mls_vessel_id ? (
            <span className='text-red-vessels' >  (Imported From Vessel ID: {mls_vessel_id})</span>
          ) : null}
        </span>
        </span>
        {(vesselName && uniqueId) &&
          <div className="text-center">
            <span className="uk-text-emphasis title">
              {vesselName}
            </span>
            <span className="uk-label stylus-bg-in-process pull-right" style={{ color: '#fff' }}>UID: {uniqueId}</span>
          </div>
        }
        <div className="form-container">
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormPage;
