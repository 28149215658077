import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmAlert } from 'react-confirm-alert';
import { Checkbox, Divider, FormControlLabel } from '@mui/material';

import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import Form from '../../../../common/Form';
import FormRow from '../../../../common/FormRow';
import FormInputWrapper from '../../../../common/FormInputWapper';
import PhoneInput from 'react-phone-input-2';
import AutoCompleteSearch from '../../../../common/Autocomplete';
import {
    CORPORATE_OWNED,
    ENTITY_AND_OR_ASSIGN,
    LEGAL_AND_OR_ASSIGN,
    LEGAL_OR_ASSIGN,
    LEGAL_OWNER,
    LLC_OWNED,
    TRUST_OWNED,
    JOINT_OWNER,
    CLIENT_REQUIRED_FIELDS
} from "../../../../../utils/signer";
import { getFormattedData, getPlainTransactionData } from '../../../../../utils/stepFilter';
import { clearClient, findClientByName, getClient, getTransactionClient } from '../../../../../redux/actions/clients';
import { getTransactionInitialById, upsertTransaction } from '../../../../../redux/actions/transaction';
import { getFigure, getPrice } from '../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../redux/actions/loading';

import Auth from '../../../../../utils/auth';
import clsx from "clsx";
import * as yup from "yup";
const HidefieldsFrom = [CORPORATE_OWNED, LLC_OWNED, TRUST_OWNED];

const useStyles = makeStyles({
    divider: {
        marginBottom: "15px",
    },
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    phoneInputDiv: {
        padding: "8px",
        marginLeft: "-7px",
        marginRight: "7px"
    }
});

const MAX_JOINT_OWNERS = 3;
const MAX_BENEFICIAL_OWNERS = 4;

const PanelFormBuyer = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({

    });

    const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();
    const classes = useStyles();
    const logged_user = Auth.getInstance().getUserData();

    const [error, setError] = useState("");
    const [isSelectedUSA, setSelectedUSA] = useState(true);
    const [isSelectedUSA2, setSelectedUSA2] = useState(true);
    const [isSelectedAssigeeCountryUSA, setSelectedAssigeeCountryUSA] = useState(true)
    const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);
    const [isHideFromOtherBroker, setIsHideFromOtherBroker] = useState(false);

    // State for save MLS checkbox
    const [isGlobal, setIsGlobal] = useState(false);

    // const [numberOfJointOwners, setNumberOfJointOwners] = useState(1);
    // const [numberOfBeneficialOwners, setNumberOfBeneficialOwners] = useState(1);
    const [isEditBuyer, setIsEditBuyer] = useState(false);

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataClient = useSelector((state) => state.clients.client);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataClients = useSelector((state) => state.clients.list);
    const dataDeal = dataTransaction || {};

    const optionsForAutocomplete = dataClients
        .map((client) => {
            return { id: client.id, value: `${client.first_name} ${client.last_name}` };
        })
        .sort((prev, next) => prev.value.localeCompare(next.value))
        .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

    useEffect(() => {
        dispatch(findClientByName());

        if (dataDeal.buyer_contact_id) {
            dispatch(getTransactionClient(dataDeal.buyer_contact_id));
            setIsEditBuyer(true);
        } else {
            setValue("country", "");
            setSelectedUSA(true);
            setIsEditBuyer(false);
        }

        return () => {
            dispatch(clearClient());
        };
    }, []);

    useEffect(() => {
        if (dataDeal && dataDeal.buyer_type) {
            setValue('buyer_type', dataDeal.buyer_type)
        } else {
            setValue('buyer_type', LEGAL_OWNER)
        }
    }, [dataTransaction]);

    useEffect(() => {
        if (dataClient) {
            // setNumberOfJointOwners(Number(dataClient["number_of_joint_owners"]) || 1);
            // setNumberOfBeneficialOwners(Number(dataClient["number_of_beneficial_owners"] || 1));

            Object.keys(dataClient).forEach((key) => {
                if (key.indexOf('trust') === 0) {
                    setValue(`trust.${key}`, dataClient[key]);
                }
                // else if (key === "joint_owners") {
                //     let jointOwners = JSON.parse(dataClient[key] || "{}");
                //     Object.keys(jointOwners).forEach((key, index) => {
                //         //fill up only fields equalt to count stored in database
                //         let jointOwnerNumber = Number(key.split("_").pop());
                //         if (jointOwnerNumber <= Number(dataClient["number_of_joint_owners"])) {
                //             setValue(`joint_owners.${key}`, jointOwners[key]);
                //         }
                //     });
                // } else if (key === "beneficial_owners") {
                //     let beneficialOwners = JSON.parse(dataClient[key] || "{}");
                //     Object.keys(beneficialOwners).forEach((key, index) => {
                //         //fill up only fields equalt to count stored in database
                //         let beneficialOwnerNumber = Number(key.split("_").pop());
                //         if (beneficialOwnerNumber <= Number(dataClient["number_of_beneficial_owners"])) {
                //             setValue(`beneficial_owners.${key}`, beneficialOwners[key]);
                //         }
                //     });
                // }
                //  else if (key === "financed_amount") {
                //     setValue(key, dataClient[key] ? getPrice(dataClient[key]) : null);
                // } 
                else {
                    setValue(key, dataClient[key]);
                }
                requiredFields.map((value) => {
                    manageRequireFields(value, getValues(value));
                });
            });
            setIsHideFromOtherBroker(dataDeal.is_buyer_hide_by_invited_broker || false);
        } else {
            setValue("country", "");
            setSelectedUSA(true);
        }
    }, [dataClient]);

    useEffect(() => {
        if (getValues("country") === "USA") {
            setSelectedUSA(true);
        } else {
            setSelectedUSA(false);
        }
    }, [watch("country")]);

    useEffect(() => {
        if (getValues("trust.trust_country") === "USA") {
            setSelectedUSA2(true);
        } else {
            setSelectedUSA2(false);
        }
    }, [watch("trust.trust_country")]);

    useEffect(() => {
        if (getValues("assignee_country") === "USA") {
            setSelectedAssigeeCountryUSA(true);
        } else {
            setSelectedAssigeeCountryUSA(false);
        }
    }, [watch("assignee_country")]);

    /**
     * On Submit buyer form with buyer details
     * @param {*} buyer 
     */
    const handleRegistration = (buyer) => {
        dispatch(setLoadingStatus(true));

        buyer = getFormattedData({
            ...buyer,
            ...buyer.trust
        });

        buyer['joint_owners'] = "";
        buyer['beneficial_owners'] = "";

        if (dataDeal && dataDeal.buyer_contact_id) {
            buyer.id = dataDeal.buyer_contact_id;
        }

        if (!isEditBuyer) {
            delete buyer.id;
        }

        if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN].indexOf(buyerType) > -1) {
            buyer['first_name'] = buyer.trust?.trust_name;
            buyer['mobile_phone'] = buyer.trust?.trust_signer_phone;
            buyer['email'] = buyer.trust?.trust_signer_email;
        }

        // if (buyer && buyer.financed_amount) {
        //     buyer.financed_amount = getFigure(buyer.financed_amount)
        // }

        dispatch(upsertTransaction({
            step: {
                db: "buyer",
                isGlobal: isGlobal,
                main: {
                    ...buyer,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    createdBy: logged_user?.id,
                    updatedBy: logged_user?.id
                },
            },
            transaction: {
                isBelonged: true,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    buyer_type: buyerType,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    is_buyer_hide_by_invited_broker: isHideFromOtherBroker
                },
                foreignkeyName: "buyer_contact_id",
            },
        }, "Buyer's")).then((data) => {
            dispatch(getTransactionInitialById(data.id));
            dispatch(setLoadingStatus(false));
            dispatch(clearClient());
            closePanel(true);
        }).catch((err) => {
            setError(err.response.data.message);
            dispatch(setLoadingStatus(false));
            setTimeout(() => {
                setError("");
            }, 5000);
        });
    }

    /**
     * on Submit if any validation occurred
     * @param {*} error 
     */
    const handleError = (error) => {
        console.log(error);
    };

    /**
     * On blur from the input field with amount in value and set $ sign
     * @param {*} ref 
     * @returns 
     */
    const handleBlur = (ref) => (e) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    /**
     * on focus on input field and remove $sign as well as leading zero
     * @param {*} ref 
     * @returns 
     */
    const handleFocus = (ref) => (e) => {
        const price = getFigure(getValues(ref));
        setValue(ref, price);
    };

    /**
     * on search and select buyer from the suggested client list
     * @param {*} value 
     * @param {*} option 
     */
    const handleSelect = (value, option) => {
        dispatch(getClient(option.id));
    };

    /**
     * On change buyer type radio button
     * @param {*} status 
     */
    const handleBuyer = (status) => {
        if ([ENTITY_AND_OR_ASSIGN, CORPORATE_OWNED, LLC_OWNED, TRUST_OWNED, JOINT_OWNER].indexOf(status) > -1) {
            confirmAlert({
                title: "Set Title",
                overlayClassName: "title-overlay-custom-class",
                message: "You have chosen a title other than Legal Name for the owner of this vessel. Confirm you have a Power of Attorney or other legal instrument allowing this person to sell this vessel",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            setValue('buyer_type', status);

                            // if ([JOINT_OWNER].indexOf(status) > -1) {
                            //     setNumberOfJointOwners(numberOfJointOwners || 1);
                            //     setValue("number_of_joint_owners", numberOfJointOwners || 1);
                            // }

                            // if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
                            //     setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
                            //     setValue("number_of_beneficial_owners", numberOfBeneficialOwners || 1);
                            // }

                            setValue("trust.trust_country", "");
                        },
                    },
                    {
                        label: "No",
                        onClick: () => { },
                    },
                ],
            });
        } else {
            setValue('buyer_type', status);

            // if ([JOINT_OWNER].indexOf(status) > -1) {
            //     setNumberOfJointOwners(numberOfJointOwners || 1);
            //     setValue("number_of_joint_owners", numberOfJointOwners || 1);
            // }

            // if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
            //     setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
            //     setValue("number_of_beneficial_owners", numberOfBeneficialOwners || 1);
            // }

            setValue("trust.trust_country", "");
        }
    }

    /**
     * manage the required field display red color in input background
     * @param {*} fieldName 
     * @param {*} fieldValue 
     */
    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        })
    }

    const isOwnerInGoodStanding = useMemo(() => getValues('trust.trust_is_good_standing'), [watch('trust.trust_is_good_standing')]);
    const buyerType = useMemo(() => getValues('buyer_type'), [watch('buyer_type')]);

    return (
        <Fragment>
            <div className="form-container panel-form">
                {menuType == 'invitation' &&
                    <FormRow>
                        <FormControlLabel
                            style={{ display: 'flex', justifyContent: 'end' }}
                            value="end"
                            control={
                                <Checkbox
                                    checked={isHideFromOtherBroker}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                    onChange={() => { setIsHideFromOtherBroker(!isHideFromOtherBroker) }}
                                />
                            }
                            label="Hide info from other broker?"
                        />
                    </FormRow>
                }
                <FormRow>
                    <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
                </FormRow>

                {error &&
                    <div className="uk-alert uk-alert-danger" data-uk-alert>
                        <p>{error}</p>
                    </div>
                }

                <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                    <FormRow>
                        <div className="radio-toolbar">
                            <FormInputWrapper>
                                <input id='legal_owner' name='legal_owner' type="radio" checked={buyerType === LEGAL_OWNER}
                                    onChange={(evt) => handleBuyer(LEGAL_OWNER)} />
                                <label htmlFor='legal_owner' className="uk-form-label radio">Legal Name/Joint Owner</label>
                            </FormInputWrapper>
                            <FormInputWrapper>
                                <input id='corporate_owned' name='corporate_owned' type="radio" checked={buyerType === CORPORATE_OWNED}
                                    onChange={(evt) => handleBuyer(CORPORATE_OWNED)} />
                                <label htmlFor='corporate_owned' className="uk-form-label radio">Corp/Trust/LLC</label>
                            </FormInputWrapper>
                            {/* <FormInputWrapper>
                                <input id='legal_or_assign' name='legal_or_assign' type="radio" checked={buyerType === LEGAL_OR_ASSIGN}
                                    onChange={(evt) => handleBuyer(LEGAL_OR_ASSIGN)} />
                                <label htmlFor='legal_or_assign' className="uk-form-label radio">Legal Name or Assigns</label>
                            </FormInputWrapper> */}
                            <FormInputWrapper>
                                <input id='legal_and_or_assign' name='legal_and_or_assign' type="radio" checked={buyerType === LEGAL_AND_OR_ASSIGN}
                                    onChange={(e) => handleBuyer(LEGAL_AND_OR_ASSIGN)} />
                                <label htmlFor='legal_and_or_assign' className="uk-form-label radio">Legal Name and/or Assigns</label>
                            </FormInputWrapper>

                            {/* <FormInputWrapper>
                                <input id='trust_owned' name='trust_owned' type="radio" checked={buyerType === TRUST_OWNED}
                                    onChange={(evt) => handleBuyer(TRUST_OWNED)} />
                                <label htmlFor='trust_owned' className="uk-form-label radio">Trust Owned</label>
                            </FormInputWrapper>
                            <FormInputWrapper>
                                <input id='llc_owned' name='llc_owned' type="radio" checked={buyerType === LLC_OWNED}
                                    onChange={(evt) => handleBuyer(LLC_OWNED)} />
                                <label htmlFor='llc_owned' className="uk-form-label radio">LLC Owned</label>
                            </FormInputWrapper> */}
                            <FormInputWrapper>
                                <input id='entity_and_or_assign' name='entity_and_or_assign' type="radio" checked={buyerType === ENTITY_AND_OR_ASSIGN}
                                    onChange={(evt) => handleBuyer(ENTITY_AND_OR_ASSIGN)} />
                                <label htmlFor='entity_and_or_assign' className="uk-form-label radio">Entity Name and/or Assigns</label>
                            </FormInputWrapper>
                            {/* <FormInputWrapper>
                                <input id='joint_owner' name='joint_owner' type="radio" checked={buyerType === JOINT_OWNER}
                                    onChange={(evt) => handleBuyer(JOINT_OWNER)} />
                                <label htmlFor='joint_owner' className="uk-form-label radio">Joint Owner</label>
                            </FormInputWrapper>
                            {[JOINT_OWNER].indexOf(buyerType) > -1 && (<FormInputWrapper label="Number of Joint Owner">
                                <select className="uk-select" {...register("number_of_joint_owners")}
                                    onChange={e => setNumberOfJointOwners(Number(e.target.value))}>
                                    {Array.from({ length: MAX_JOINT_OWNERS }).map((_, index) => {
                                        const ownerIndex = index + 1;
                                        return (
                                            <option value={ownerIndex} key={ownerIndex}>
                                                {ownerIndex}
                                            </option>
                                        );
                                    })}
                                </select>
                            </FormInputWrapper>)} */}
                        </div>
                    </FormRow>
                    {[LEGAL_AND_OR_ASSIGN].indexOf(buyerType) > -1 &&
                        <FormRow>
                            <FormInputWrapper size="three">
                                <h3>Assignor</h3>
                            </FormInputWrapper>
                        </FormRow>
                    }
                    {[ENTITY_AND_OR_ASSIGN, ...HidefieldsFrom].includes(getValues('buyer_type'))
                        ? <></>
                        : <>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">First Name*</label>
                                    <input value={getValues('first_name')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                                        {...register('first_name', {
                                            onChange: e => {
                                                manageRequireFields('first_name', e.target.value);
                                            }
                                        })} type="text" />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="middle_name">Middle Name</label>
                                    <input className="uk-input" type="text" {...register("middle_name")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="last_name">Last Name*</label>
                                    <input value={getValues('last_name')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                                        {...register('last_name', {
                                            onChange: e => {
                                                manageRequireFields('last_name', e.target.value);
                                            }
                                        })} type="text" />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="email">Email*</label>
                                    <input value={getValues('email')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                                        {...register('email', {
                                            onChange: e => {
                                                manageRequireFields('email', e.target.value);
                                            }
                                        })} type="email" />
                                </FormInputWrapper>
                                <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                    <label className="uk-form-label" htmlFor="name">Mobile Phone*</label>
                                    <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country='us'
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        onFocus={() => { }}
                                        inputStyle={{
                                            background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                                            borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                                        }}
                                        buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                                        value={getValues("mobile_phone")}
                                        onChange={phone => {
                                            setValue('mobile_phone', phone, { shouldValidate: true })
                                            manageRequireFields('mobile_phone', phone);
                                        }} />
                                </div>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="nationality">
                                        Citizen Of
                                    </label>
                                    <input className="uk-input" type="text" {...register("nationality")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Address*">
                                    <input className="uk-input" type="text" {...register("address_1")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Address 2">
                                    <input className="uk-input" type="text" {...register("address_2")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Address 3">
                                    <input className="uk-input" type="text" {...register("address_3")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Country*">
                                    <select className="uk-select" {...register("country")} defaultValue="">
                                        {Country.map((country) => {
                                            return (
                                                <option value={country.code} key={country.code}>
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="city">City*</label>
                                    <input className="uk-input" type="text" {...register("city")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label" htmlFor="state">State*</label>
                                    {isSelectedUSA
                                        ? (
                                            <select className="uk-select" {...register("state")}>
                                                <option defaultValue={undefined}></option>
                                                {StatesForUSA.map((state) => {
                                                    return (
                                                        <option value={state.code} key={state.code}>
                                                            {state.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        )
                                        : (
                                            <input className="uk-input" type="text" {...register("state")} />
                                        )}
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Zip/Postal Code*</label>
                                    <input className="uk-input" type="text" {...register("postal_code")} />
                                </FormInputWrapper>
                            </FormRow>
                        </>
                    }

                    {[LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN].indexOf(buyerType) > -1 &&
                        <>
                            <FormRow>
                                <FormInputWrapper size="three">
                                    <h3>Assignee</h3>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">First Name</label>
                                    <input className="uk-input" type="text" name="assignee_first_name"  {...register("assignee_first_name")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Last Name</label>
                                    <input className="uk-input" type="text" name="assignee_last_name" {...register("assignee_last_name")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Citizen Of</label>
                                    <input className="uk-input" type="text" name="assignee_citizen_of" {...register("assignee_citizen_of")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Email</label>
                                    <input className="uk-input" type="text" name="assignee_email" {...register("assignee_email")} />
                                </FormInputWrapper>
                                <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                    <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
                                    <input type="hidden" required name="assignee_mobile_phone"  {...register("assignee_mobile_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country='us'
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        onFocus={() => { }}
                                        value={getValues("assignee_mobile_phone")}
                                        onChange={phone => { setValue('assignee_mobile_phone', phone, { shouldValidate: true }) }} />
                                </div>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Country</label>
                                    <select className="uk-select" {...register("assignee_country")}>
                                        {Country.map((country) => {
                                            return (
                                                <option value={country.code} key={country.code}>
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Address 1</label>
                                    <input className="uk-input" type="text" name="assignee_address_1"  {...register("assignee_address_1")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Address 2</label>
                                    <input className="uk-input" type="text" name="assignee_address_2" {...register("assignee_address_2")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Address 3</label>
                                    <input className="uk-input" type="text" name="assignee_address_3" {...register("assignee_address_3")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">City</label>
                                    <input className="uk-input" type="text" name="assignee_city" {...register("assignee_city")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">State</label>
                                    {isSelectedAssigeeCountryUSA
                                        ? (
                                            <select className="uk-select" name="assignee_state" {...register("assignee_state")}>
                                                <option value="" key="default">Select</option>
                                                {StatesForUSA.map((state) => {
                                                    return (
                                                        <option value={state.code} key={state.code}>
                                                            {state.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        )
                                        : (
                                            <input className="uk-input" type="text" name="assignee_state" {...register("assignee_state")} />
                                        )
                                    }
                                </FormInputWrapper>
                                <FormInputWrapper>
                                    <label className="uk-form-label">Zip Code</label>
                                    <input className="uk-input" type="text" name="assignee_zipcode" {...register("assignee_zipcode")} />
                                </FormInputWrapper>
                            </FormRow>
                        </>
                    }

                    {/* {[JOINT_OWNER].indexOf(buyerType) > -1 &&
                        (
                            <>
                                <FormRow>
                                    <FormInputWrapper size="four">
                                        <h3>Joint Owner{numberOfJointOwners > 1 ? "(s)" : ""}</h3>
                                    </FormInputWrapper>
                                </FormRow>
                                {numberOfJointOwners == 1
                                    ? (
                                        <FormRow key={`joint_owner_1`} size="four">
                                            <FormInputWrapper label={`Joint Owner First Name *`} size="one">
                                                <input className="uk-input" type="text" {...register(`joint_owners.first_name_1`)} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label={`Joint Owner Last Name *`} size="one">
                                                <input className="uk-input" type="text" {...register(`joint_owners.last_name_1`)} />
                                            </FormInputWrapper>
                                            <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                                <label className="uk-form-label" htmlFor="name">Joint Owner Phone *</label>
                                                <input type="hidden" required name="joint_owners.phone_1"  {...register("joint_owners.phone_1")} />
                                                <PhoneInput
                                                    placeholder='+'
                                                    country='us'
                                                    inputClass={classes.phone_input}
                                                    containerClass={classes.phone_container}
                                                    autoFocus={false}
                                                    onFocus={() => { }}
                                                    value={getValues("joint_owners.phone_1")}
                                                    onChange={phone => { setValue('joint_owners.phone_1', phone, { shouldValidate: true }) }} />
                                            </div>
                                            <FormInputWrapper label={`Joint Owner Email *`} size="one">
                                                <input className="uk-input" type="text" {...register(`joint_owners.email_1`)} />
                                            </FormInputWrapper>
                                        </FormRow>
                                    )
                                    : (
                                        Array.from({ length: numberOfJointOwners }).map((_, index) => {
                                            const realIndex = index + 1;
                                            return (
                                                <FormRow key={`joint_owner_${index}`} size="four">
                                                    <FormInputWrapper label={`Joint Owner ${realIndex} First Name *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`joint_owners.first_name_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Joint Owner ${realIndex} Last Name *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`joint_owners.last_name_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                                        <label className="uk-form-label" htmlFor="name">{`Joint Owner ${realIndex} Phone *`}</label>
                                                        <input type="hidden" required name={`joint_owners.phone_${realIndex}`}  {...register(`joint_owners.phone_${realIndex}`)} />
                                                        <PhoneInput
                                                            placeholder='+'
                                                            country='us'
                                                            inputClass={classes.phone_input}
                                                            containerClass={classes.phone_container}
                                                            autoFocus={false}
                                                            onFocus={() => { }}
                                                            value={getValues(`joint_owners.phone_${realIndex}`)}
                                                            onChange={phone => { setValue(`joint_owners.phone_${realIndex}`, phone, { shouldValidate: true }) }} />
                                                    </div>
                                                    <FormInputWrapper label={`Joint Owner ${realIndex} Email *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`joint_owners.email_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                </FormRow>
                                            );
                                        })
                                    )
                                }
                            </>
                        )
                    } */}

                    {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(buyerType) > -1 &&
                        (
                            <>
                                <FormRow>
                                    <FormInputWrapper size="three">
                                        <h3>Corporation/LLC/Trust Owned</h3>
                                    </FormInputWrapper>
                                </FormRow>
                                {/* <FormRow>
                                    <FormInputWrapper size="one" label="Number of Beneficial Owner">
                                        <select className="uk-select" {...register("number_of_beneficial_owners")}
                                            onChange={e => setNumberOfBeneficialOwners(Number(e.target.value))}>
                                            {Array.from({ length: MAX_BENEFICIAL_OWNERS }).map((_, index) => {
                                                const ownerIndex = index + 1;
                                                return (
                                                    <option value={ownerIndex} key={ownerIndex}>
                                                        {ownerIndex}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </FormInputWrapper>
                                </FormRow> */}
                                <FormRow>
                                    <FormInputWrapper size="two" label="Corporation/LLC/Trust Name *">
                                        <input className="uk-input" type="text" {...register("trust.trust_name")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Tax ID">
                                        <input className="uk-input" type="text" {...register("trust.trust_tax_id")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Address *">
                                        <input className="uk-input" type="text" {...register("trust.trust_address")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Unit/Apt">
                                        <input className="uk-input" type="text" {...register("trust.trust_unit_apt")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Country">
                                        <select className="uk-select" {...register("trust.trust_country")} defaultValue="" >
                                            <option value=""></option>
                                            {Country.map((country) => {
                                                return (
                                                    <option value={country.code} key={country.code}>
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="City *">
                                        <input className="uk-input" type="text" {...register("trust.trust_city")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="State *">
                                        {isSelectedUSA2
                                            ? (
                                                <select className="uk-select" {...register("trust.trust_state")}>
                                                    <option defaultValue={undefined}></option>
                                                    {StatesForUSA.map((state) => {
                                                        return (
                                                            <option value={state.code} key={state.code}>
                                                                {state.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            )
                                            : (
                                                <input className="uk-input" type="text" {...register("trust.trust_state")} />
                                            )
                                        }
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Zip">
                                        <input className="uk-input" type="text" {...register("trust.trust_zip")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Entity in good standing">
                                        <div className="radio-toolbar">
                                            <input type="radio" id="radioStandingYes" name="radioStanding" checked={isOwnerInGoodStanding}
                                                onChange={(e) => setValue('trust.trust_is_good_standing', e.target.checked)} />
                                            <label className="uk-form-label radio">Yes</label>
                                            <input type="radio" id="radioStandingNo" name="radioStanding" checked={!isOwnerInGoodStanding}
                                                onChange={(e) => setValue('trust.trust_is_good_standing', !e.target.checked)} />
                                            <label className="uk-form-label radio">No</label>
                                        </div>
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Jurisdiction of Owner">
                                        <input className="uk-input" type="text" {...register("trust.trust_jurisdiction_of_owner")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper label="Name of Signer *">
                                        <input className="uk-input" type="text" {...register("trust.trust_signer_name")} />
                                    </FormInputWrapper>
                                    <FormInputWrapper label="Title of Signer *">
                                        <input className="uk-input" type="text" {...register("trust.trust_signer_title")} />
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                        <label className="uk-form-label" htmlFor="name">Phone</label>
                                        <input type="hidden" required name="trust.trust_signer_phone"  {...register("trust.trust_signer_phone")} />
                                        <PhoneInput
                                            placeholder='+'
                                            country='us'
                                            inputClass={classes.phone_input}
                                            containerClass={classes.phone_container}
                                            autoFocus={false}
                                            onFocus={() => { }}
                                            value={getValues("trust.trust_signer_phone")}
                                            onChange={phone => { setValue("trust.trust_signer_phone", phone, { shouldValidate: true }) }} />
                                    </div>
                                    <FormInputWrapper label="Email *">
                                        <input className="uk-input" type="text" {...register("trust.trust_signer_email")} />
                                    </FormInputWrapper>
                                </FormRow>

                                {/* <FormRow>
                                    <FormInputWrapper size="four">
                                        <h3>Additional Beneficial Owner{numberOfBeneficialOwners > 1 ? "(s)" : ""}</h3>
                                    </FormInputWrapper>
                                </FormRow>
                                {numberOfBeneficialOwners == 1
                                    ? (
                                        <FormRow key={`beneficial_owner_1`} size="four">
                                            <FormInputWrapper label={`Beneficial Owner Name *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.name_1`)} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label={`Beneficial Owner Title *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.title_1`)} />
                                            </FormInputWrapper>
                                            <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                                <label className="uk-form-label" htmlFor="name">Phone</label>
                                                <input type="hidden" required name="beneficial_owners.phone_1"  {...register("beneficial_owners.phone_1")} />
                                                <PhoneInput
                                                    placeholder='+'
                                                    country='us'
                                                    inputClass={classes.phone_input}
                                                    containerClass={classes.phone_container}
                                                    autoFocus={false}
                                                    onFocus={() => { }}
                                                    value={getValues("beneficial_owners.phone_1")}
                                                    onChange={phone => { setValue("beneficial_owners.phone_1", phone, { shouldValidate: true }) }} />
                                            </div>
                                            <FormInputWrapper label={`Beneficial Owner Email *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.email_1`)} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label={`Beneficial Owner Address *`} size="one">
                                                <input className="uk-input" type="text" {...register(`beneficial_owners.address_1`)} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label="Beneficial Owner Unit/Apt *" size="one">
                                                <input className="uk-input" type="text" {...register("beneficial_owners.unit_apt_1")} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label="Beneficial Owner City *" size="one">
                                                <input className="uk-input" type="text" {...register("beneficial_owners.city_1")} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label="Beneficial Owner State *" size="one">
                                                <input className="uk-input" type="text" {...register("beneficial_owners.state_1")} />
                                            </FormInputWrapper>
                                            <FormInputWrapper label="Beneficial Owner Country *" size="one">
                                                <input className="uk-input" type="text" {...register("beneficial_owners.country_1")} />
                                            </FormInputWrapper>
                                            <FormRow>
                                                <FormInputWrapper label="Beneficial Owner Zip Code *" size="one">
                                                    <input className="uk-input" type="text" {...register("beneficial_owners.postal_code_1")} />
                                                </FormInputWrapper>
                                            </FormRow>
                                        </FormRow>
                                    )
                                    : (Array.from({ length: numberOfBeneficialOwners }).map((_, index) => {
                                        const realIndex = index + 1;
                                        return (
                                            <>
                                                <FormRow key={`beneficial_owner_${index}`} size="four">
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Name *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.name_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Title *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.title_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                                        <label className="uk-form-label" htmlFor="name">{`Beneficial Owner ${realIndex} Phone *`}</label>
                                                        <input type="hidden" required name={`beneficial_owners.phone_${realIndex}`}  {...register(`beneficial_owners.phone_${realIndex}`)} />
                                                        <PhoneInput
                                                            placeholder='+'
                                                            country='us'
                                                            inputClass={classes.phone_input}
                                                            containerClass={classes.phone_container}
                                                            autoFocus={false}
                                                            onFocus={() => { }}
                                                            value={getValues(`beneficial_owners.phone_${realIndex}`)}
                                                            onChange={phone => { setValue(`beneficial_owners.phone_${realIndex}`, phone, { shouldValidate: true }) }}
                                                        />
                                                    </div>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Email *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.email_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Address *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.address_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Unit/Apt *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.unit_apt_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} City *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.city_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} State *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.state_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormInputWrapper label={`Beneficial Owner ${realIndex} Country *`} size="one">
                                                        <input className="uk-input" type="text" {...register(`beneficial_owners.country_${realIndex}`)} />
                                                    </FormInputWrapper>
                                                    <FormRow>
                                                        <FormInputWrapper label={`Beneficial Owner ${realIndex} Zip Code *`} size="one">
                                                            <input className="uk-input" type="text" {...register(`beneficial_owners.postal_code_${realIndex}`)} />
                                                        </FormInputWrapper>
                                                    </FormRow>
                                                </FormRow>
                                                <Divider className={classes.divider} />
                                            </>
                                        );
                                    }))
                                } */}
                            </>
                        )}

                    {HidefieldsFrom.includes(getValues('buyer_type'))
                        ? <></>
                        : <>
                            <FormRow>
                                <FormInputWrapper size="three">
                                    <h3>Financing</h3>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Bank Name</label>
                                    <input className="uk-input" type="text" {...register("bank_name")} />
                                </FormInputWrapper>
                                {/* <FormInputWrapper label="">
                            <label className="uk-form-label">Financed Amount</label>
                            <input className="uk-input" type="text" {...register("financed_amount")}
                                onBlur={handleBlur("financed_amount")} onFocus={handleFocus("financed_amount")} />
                        </FormInputWrapper> */}
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Contact Name</label>
                                    <input className="uk-input" type="text" {...register("contact_name")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Title</label>
                                    <input className="uk-input" type="text" {...register("bank_title")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                    <label className="uk-form-label" htmlFor="name">Phone</label>
                                    <input type="hidden" required name="bank_phone"  {...register("bank_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country='us'
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        onFocus={() => { }}
                                        value={getValues("bank_phone")}
                                        onChange={phone => { setValue("bank_phone", phone, { shouldValidate: true }) }} />
                                </div>
                                <FormInputWrapper label="">
                                    <label className="uk-form-label">Email</label>
                                    <input className="uk-input" type="text" {...register("bank_email")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper size="three">
                                    <h3>Additional Contact</h3>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Name">
                                    <input className="uk-input" type="text" {...register("additional_name")} />
                                </FormInputWrapper>
                                <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                    <label className="uk-form-label" htmlFor="name">Phone</label>
                                    <input type="hidden" required name="additional_phone"  {...register("additional_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country={'us'}
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        onFocus={() => { }}
                                        value={getValues("additional_phone")}
                                        onChange={phone => { setValue("additional_phone", phone, { shouldValidate: true }) }} />
                                </div>
                                <FormInputWrapper label="Email">
                                    <input className="uk-input" type="text" {...register("additional_email")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Title">
                                    <input className="uk-input" type="text" {...register("additional_title")} />
                                </FormInputWrapper>
                            </FormRow>
                        </>
                    }

                    <FormRow>
                        <div className={`one-column mt-3 mb-3`}>
                            <input className="uk-checkbox " type="checkbox" checked={isGlobal} onChange={() => setIsGlobal(!isGlobal)} />
                            <label className={`uk-form-label font-weight-bold`}>Save CRM Client?</label>
                        </div>
                    </FormRow>
                    <FormRow>
                        <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                        <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                    </FormRow>
                </Form>
            </div>
        </Fragment>
    )
}
export default PanelFormBuyer;